import axios from "axios";
import i18n from "@/libs/i18n";

export default {

    namespaced: true,

    state:{
        user:{
            acl: {}
        },
        listaUsuarios: [],
        conveniados: [],
        errors_user: false,
    },

    mutations:{
        STORE_USER(state, user){
            state.user = user
        },
        STORE_LISTA_USUARIOS(state, user){
            state.listaUsuarios = user
        },
        STORE_USER_ERROR_LOGIN(state, error){
            state.errors_user = error
        },

        STORE_CONVENIADOS(state, conveniados){
            state.conveniados = conveniados
        },

        UPDATE_ACL(state, acl) {
            state.user.acl = acl
        }

    },

    getters: {

        conveniadosSemCadastroUsuario(state){

            let result = state.conveniados.filter(conveniado => {
                return !state.listaUsuarios.some(usuario => usuario.conveniado_id === conveniado.id)
            });


            result.unshift({
                id: null,
                conveniado_id: null,
                conveniado_nome: i18n.t('Selecione_Um_Dentista')
            });

            return result;

        }

    },

    actions: {

        listaUsuarios(context) {

            axios('odontodocs/usuarios').then(res => {
                context.commit('STORE_LISTA_USUARIOS', res.data)
            })

        },

        listaConveniados(context, laboSelecionado){
            axios(`odontodocs/pedidos/conveniados/${laboSelecionado}`).then( (res) => {
                context.commit('STORE_CONVENIADOS', res.data)
            })

        },
    }

}
