<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- CARD ESQUERDA -->
      <b-col cols="12" xl="9" md="8">
        <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show="">
          <div class="alert-body my-2" v-for="(error, index) in getErrors.messageArray" :key="index">
            {{ error[0] }}
          </div>
          <div class="alert-body">{{ getErrors.message }}</div>
        </b-alert>

        <validation-observer ref="formPedidos">
          <b-form @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <h5 class="text-center mt-2">{{ $t('Pedido_para') }}: <span class="text-primary">{{ nomeLaboSelecionado
                  }}</span></h5>

              <!-- DETALHES DO PEDIDO-->
              <b-card-body class="invoice-padding pt-0" style="padding-bottom: 0">
                <b-row class="invoice-spacing">
                  <!-- DENTISTA CONVENIADO -->
                  <b-col sm="6" md="5" lg="5">
                    <label class="d-inline">{{ $t('Dentista_Conveniado_Opcional') }}</label>
                    <v-select 
                      v-model="form.conveniado_id" 
                      label="conveniado_nome" 
                      :reduce="conveniado => conveniado.id"
                      :options="filteredConveniados" 
                      :clearable="podeLimparSelectConveniados"
                      >
                      <div slot="no-options">
                        {{ $t('Dentista_Conveniado_Nao_Encontrado') }}
                      </div>
                    </v-select>
                  </b-col>

                  <!-- PACIENTE -->
                  <b-col cols="12" sm="6" md="7" lg="7">
                    <label class="d-inline">{{ $t('Paciente_Obrigatorio') }}</label>
                    <validation-provider 
                    #default="{ errors }" 
                    :name="$t('Paciente')" 
                    rules="max:50|required"
                    >
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon"></feather-icon>
                        </b-input-group-prepend>
                        <b-form-input id="paciente" v-model="form.paciente"/>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                </b-row>
                <b-row class="invoice-spacing">

                  <!-- MATERIAIS -->
                  <b-col cols="12">
                    <!--                    <Materiais @materiais="form.materiais = $event"/>-->
                    <ModalMateriaisDropdown 
                      ref="materiaisDropdown" 
                      @materiais="form.materiais = $event"
                      :componentesEditar="[]" 
                    />
                  </b-col>
                </b-row>

              </b-card-body>

              <!-- SERVIÇOS ITEMS -->

              <b-card-body 
                  style="background-color: #f5f5f5; padding: 10px;" 
                  v-for="(servico, index) in form.servicos"
                  :key="index"
              >
                <b-card class="mb-0">
                  <template #header>
                    <b-col sm="9">
                      <h4 class="text-primary">{{ servico.nome ? servico.nome : $t('Nenhum_Servico_Selecionado') }}</h4>
                    </b-col>
                    <b-col cols="3">
                      <strong class="text-primary align-items-center">
                          {{ $t('Subtotal') }}: {{ servico.subTotal + servico.subTotalProdutos | formataMoeda }}
                      </strong>
                    </b-col>
                    <b-col>
                      <b-button 
                          v-if="index > 0" 
                          size="sm" 
                          v-b-toggle="'collapse-' + index" 
                          variant="outline-danger"
                          class="btn-icon float-right"
                      >
                        <feather-icon 
                            icon="XIcon" 
                            class="cursor-pointer text-danger" 
                            @click="removeItem(index)" 
                        />
                      </b-button>
                      <b-button 
                          size="sm" 
                          v-b-toggle="'collapse-' + index" 
                          variant="outline-primary"
                          class="btn-icon float-right mx-1"
                        >
                        <feather-icon icon="ChevronDownIcon" />
                      </b-button>
                    </b-col>
                  </template>

                  <b-collapse 
                      type="margin" 
                      visible 
                      :id="`collapse-${index}`"
                      >
                    <div 
                        ref="form" 
                        class="repeater-form" 
                        :style="{ height: trHeight }">

                      <b-row ref="row">
                        <b-col cols="12">
                          <div class="d-none d-lg-flex">
                            <b-row class="flex-grow-1 px-1">
                              <b-col cols="12" lg="5"> {{ $t('Servico') }} </b-col>
                              <b-col cols="12" lg="2"> {{ $t('Quantidade') }} </b-col>
                              <b-col cols="12" lg="3"> {{ $t('Valor_Unitario') }} </b-col>
                              <b-col cols="12" lg="2"> {{ $t('Desconto') }} </b-col>
                            </b-row>
                            <div class="form-item-action-col" />
                          </div>

                          <div class="border rounded">
                            <b-row class="flex-grow-1 p-1">

                              <!-- SERVIÇO-->
                              <b-col cols="12" lg="5">
                                <label class="d-inline d-lg-none">{{ $t('Servico') }}</label>
                                    <validation-provider 
                                    #default="{ errors }" 
                                    :name="$t('Servico')" 
                                    rules="required"
                                >
                                  <v-select 
                                      label="nome" 
                                      v-model="servico.nome" 
                                      :clearable="false"
                                      :options="categorias.servicos" 
                                      class="mb-2 item-selector-title"
                                      :placeholder="$t('Selecione_Um_Servico')"
                                      :selectable="(option) => option.posicao > 0"
                                      @input="val => mudouServico(index, val)"
                                      >
                                    <template #option="{ nome, posicao }">
                                      <h5 
                                          v-if="posicao === 0" 
                                          class="text-primary" 
                                          style="margin: 0"
                                          >
                                        {{ nome }}
                                      </h5>
                                        <em 
                                            v-else 
                                            class="pl-2"
                                        >
                                      {{ nome }}
                                    </em>

                                    </template>
                                  </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>

                              <!-- QUANTIDADE-->
                              <b-col cols="12" lg="2">
                                <label class="d-inline d-lg-none">{{ $t('Quantidade') }}</label>
                                <validation-provider 
                                    #default="{ errors }" 
                                    :name="$t('Quantidade')"
                                    rules="maior_zero|required"
                                    >
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  <b-form-spinbutton 
                                        v-model="servico.quantidade" 
                                        :disabled="servico.id ? false : true"
                                        min="1" 
                                        @change="alterouQuantidade(index, servico.quantidade)" 
                                    />
                                </validation-provider>
                              </b-col>

                              <!-- VALOR UNITÁRIO-->
                              <b-col cols="12" lg="3">
                                <label class="d-inline d-lg-none">{{ $t('Valor_Unitario') }}</label>
                                <money 
                                    v-bind="money" 
                                    :disabled="true" 
                                    :value="servico.valor"
                                    :state="errors.length > 0 ? false : null" 
                                    class="form-control mb-2" 
                                    type="text" 
                                  />
                              </b-col>

                              <!-- DESCONTO-->
                              <b-col cols="12" lg="2">
                                <label class="d-inline d-lg-none">{{ $t('Desconto') }} %</label>
                                <b-form-input 
                                    :disabled="true" 
                                    type="number" 
                                    class="mb-2" 
                                    v-model="servico.desconto" 
                                />
                              </b-col>

                              <!-- ODONTOGRAMA-->
                              <b-col cols="12" lg="7">
                                <label class="d-inline d-lg-none">{{ $t('Dentes') }}</label>
                                <div class="demo-inline-spacing text-center mt-0">
                                  <b-form-radio 
                                      v-model="servico.odontograma" 
                                      class="custom-control-primary"
                                      name="permanente" 
                                      value="odontograma"
                                      @change="(servico.num_dente = []), $refs.odontogramaComponente[0].desativaTodos()"
                                  >
                                    {{ $t('Permanente') }}
                                  </b-form-radio>

                                  <b-form-radio 
                                      v-model="servico.odontograma" 
                                      class="custom-control-primary"
                                      name="deciduos" 
                                      value="odontograma-crianca"
                                      @change=" (servico.num_dente = []), $refs.odontogramaComponente[0].desativaTodos()"
                                  >
                                    {{ $t('Deciduos') }}
                                  </b-form-radio>
                                </div>
                                <keep-alive>
                                  <validation-provider 
                                      #default="{ errors }" 
                                      :name="$t('Numero_Dente')" 
                                      rules="required"
                                  >
                                    <component 
                                        :is="servico.odontograma" 
                                        ref="odontogramaComponente"
                                        :dentes-selecionados="servico.num_dente" 
                                        class="h-25" 
                                        style="z-index: 99999"
                                        v-model="servico.dente"
                                        @denteSelecionado="servico.num_dente = $event, servico.dente = $event.toString()" 
                                    />
                                    <small 
                                        class="text-danger" 
                                        style="position: relative; top: 92px;"
                                    >
                                    {{ errors[0] }}
                                  </small>
                                  </validation-provider>
                                </keep-alive>

                              </b-col>

                              <!-- COR-->
                              <b-col>
                                <label class="d-inline"> <span class="font-weight-bold">{{ $t('Escala_Cor') }}:
                                  </span></label>
                                <validation-provider 
                                    #default="{ errors }" 
                                    name="Cor do Dente" 
                                    rules="max:80"
                                >
                                  <b-form-textarea 
                                      v-model="servico.cor_dente" 
                                      :state="errors.length > 0 ? false : null"
                                      class="mb-2" type="text" 
                                    />

                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>

                                <label 
                                class="d-inline"> 
                                    <span class="font-weight-bold">
                                      {{ $t('Observacao') }}:
                                    </span>
                                </label>
                                <validation-provider 
                                #default="{ errors }" 
                                name="Observações" 
                                rules="max:500"
                                >
                                  <b-form-textarea 
                                      v-model="servico.observacao_item"
                                      :state="errors.length > 0 ? false : null" 
                                      class="mb-2" 
                                      type="text" 
                                  />

                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>

                              </b-col>

                              <!-- ADD PRODUTOS-->
                              <b-col cols="12">
                                <b-button 
                                    class="mr-1 mb-1" 
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                                    size="sm"
                                    variant="success" 
                                    @click="addNewProductInItemForm(index)"
                                >
                                  <feather-icon icon="PlusIcon"></feather-icon> {{ $t('Produto') }}
                                </b-button>
                              </b-col>

                              <b-col cols="12" v-for="(produto, indexProd) in servico.produtos" :key="indexProd">
                                <div class="d-flex">
                                  <b-row class="flex-grow-1">

                                    <!-- PRODUTOS -->
                                    <b-col cols="8" lg="5" md="6">
                                      <b-form-group 
                                          :label="$t('Selecione_Um_Produto')" 
                                          label-for="produto"
                                          >
                                        <validation-provider 
                                            #default="{ errors }" 
                                            mode="eager" 
                                            name="Produto"
                                            rules="required"
                                        >
                                          <v-select 
                                              id="produto" 
                                              v-model="produto.nome" 
                                              :clearable="false"
                                              :options="categorias.produtos" 
                                              label="nome"
                                              @input="prod => mudouProduto(index, indexProd, prod)"
                                          >
                                            <template #no-options>
                                              {{ $t('Produto_Nao_Encontrado') }}
                                            </template>
                                          </v-select>
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>

                                    <!-- PRODUTOS QUANTIDADE -->
                                    <b-col cols="12" lg="2">
                                      <b-form-group 
                                          :label="$t('Quantidade')" 
                                          label-for="produto_quantidade"
                                      >
                                        <validation-provider 
                                            #default="{ errors }" 
                                            :name="$t('Quantidade')"
                                            rules="maior_zero|required"
                                        >
                                          <small class="text-danger">
                                              {{ errors[0] }}
                                          </small>
                                          <b-form-spinbutton 
                                              v-model="produto.quantidade" 
                                              min="1"
                                              @change="alterouQuantidadeProduto(index, indexProd, produto.quantidade)" 
                                          />
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>

                                    <!-- PRODUTOS VALOR -->
                                    <b-col>
                                      <label class="d-inline">{{ $t('Valor') }}</label>
                                      <validation-provider 
                                          #default="{ errors }" 
                                          name="Valor" 
                                          rules="positive"
                                      >
                                        <money 
                                            v-bind="money" 
                                            :disabled="true" 
                                            :value="produto.valor"
                                            :state="errors.length > 0 ? false : null" 
                                            class="form-control mb-2"
                                            type="text" 
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                    </b-col>

                                    <!-- PRODUTOS SUBTOTAL -->
                                    <b-col>
                                      <label class="d-inline">{{ $t('Subtotal') }}</label>
                                      <validation-provider 
                                          #default="{ errors }" 
                                          name="Valor" 
                                          rules="positive"
                                      >
                                        <money 
                                            v-bind="money" 
                                            :disabled="true" 
                                            :value="produto.subTotal"
                                            :state="errors.length > 0 ? false : null" 
                                            class="form-control mb-2"
                                            type="text" 
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                    </b-col>
                                    <b-col cols="12" lg="1" class="mt-2">
                                      <b-button class="btn-icon" 
                                          variant="outline-danger"
                                          @click="excluirProduto(index, indexProd)"
                                        >
                                        <feather-icon icon="XCircleIcon"></feather-icon>
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>

                            </b-row>

                            <!-- REMOVE SERVIÇO-->
                            <div v-if="index > 0"
                              class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <feather-icon 
                                  size="16" 
                                  icon="XIcon" 
                                  class="cursor-pointer text-danger"
                                  @click="removeItem(index)" 
                              />
                            </div>
                          </div>

                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col v-if="servico.data_prazo">
                          <strong class="text-primary"> 
                            {{ $t('Prazo_Entrega') }}: {{ servico.data_prazo | convertData }} 
                          </strong> 
                          <em class="text-muted"> 
                            {{ $t('Obs_Prazo_Variavel') }}
                          </em>
                        </b-col>
                      </b-row>

                    </div>

                  </b-collapse>
                </b-card>
              </b-card-body>


              <!-- BOTÕES -> SERVIÇOS E PRODUTOS -->
              <b-row cols="12" class="m-1">

                <b-button 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                    size="sm" 
                    variant="primary" 
                    block
                    @click="addNewItemInItemForm()"
                    >
                  <feather-icon icon="PlusIcon"></feather-icon> {{ $t('Servico') }}
                </b-button>

              </b-row>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
              </b-card-body>

              <!-- Note -->
              <b-card-body class="invoice-padding pt-2" body-border-variant="primary">
                <span class="font-weight-bold">{{ $t('Anexar_Arquivos_Imagens') }}: </span> <br>
                <small v-if="statusUpload !== null" class="text-danger">{{ $t('Maximo_Arquivos') }}</small>
                <!-- Accept specific image formats by extension -->
                <b-row>
                  <b-col>
                    <b-form-file 
                        :placeholder="$t('Selecione_Imagens')" 
                        multiple
                        accept="image/*, .pdf, .stl, .zip, .dcm, .rar, .mov, .mp4, .mkv" 
                        browse-text="Upload"
                        v-model="files" 
                        :state="statusUpload" 
                        @change="filesUpload"
                    >
                      <template slot="file-name" slot-scope="{ names }">
                        <b-badge v-if="index < 3" 
                            class="mr-1" 
                            variant="light-primary" 
                            v-for="(name, index) in names"
                            :key="index">
                        {{ name }}
                      </b-badge>
                        <b-badge v-if="names.length > 3" variant="secondary" class="ml-1">
                          + {{ names.length - 3 }} {{ $t('Mais_Arquivos') }}
                        </b-badge>
                      </template>
                    </b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-col>
                  <b-col cols="2" v-if="files.length">
                    <b-button variant="outline-danger" @click="files = []">{{ $t('Limpar') }}</b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- CARD DIREITA-->

      <b-col 
          cols="12" 
          md="4" 
          xl="3" 
          class="invoice-actions mt-md-0 mt-2" 
          v-if="!mostraDetalhesPedido"
      >

        <!-- Buttons -->
        <b-card>

          <!-- AUTORIZAR USUÁRIO -->
          <!--          <b-row class="mb-1">
                      <b-col>
                        <label for="usuario" class="text-nowrap mr-50">Permitir Acesso (Usuário):</label>
                        <v-select
                            label="name"
                            :multiple="true"
                            v-model="form.user_id"
                            :options="usuarios"
                            class="mb-2 item-selector-title"
                            :reduce="user => user.id"
                            :selectable="(option) => option.tipo_usuario > 1"
                        >
                        </v-select>
                      </b-col>
                    </b-row>-->

          <!-- TAXA ENTREGA -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">{{ $t('Incluir_Taxa_Entrega') }}</label>
            <b-form-checkbox 
                id="entrega" 
                v-model="form.entrega" 
                :checked="true" 
                switch
            />
          </div>

          <!-- SOLICITAR RETIRADA -->
          <div class="d-flex justify-content-between align-items-center my-1 mb-2">
            <label for="clientNotes">{{ $t('Solicitar_Retirada') }}</label>
            <b-form-checkbox 
                id="retirada" 
                v-model="form.retirada" 
                :checked="true" 
                switch 
            />
          </div>

          <hr>

          <!-- VALORES TOTAIS-->
          <b-row>
            <!--DESCRIÇÃO VALORES TOTAIS DE PEDIDO -->
            <b-col class="mt-md-12 d-flex justify-content-end">
              <div class="invoice-total-wrapper">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">{{ $t('Servicos') }}:</p>
                  <p class="invoice-total-amount"> {{ valorTotalPedido.valorBruto | formataMoeda }} </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">{{ $t('Produtos') }}:</p>
                  <p class="invoice-total-amount"> {{ valorTotalProdutos | formataMoeda }} </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">{{ $t('Transporte') }}: </p>
                  <p class="invoice-total-amount"> {{ valorEntrega | formataMoeda }}</p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">{{ $t('Desconto') }}: </p>
                  <p class="invoice-total-amount"> <span v-if="valorTotalPedido.totalDesconto"> - </span> {{
                    valorTotalPedido.totalDesconto | formataMoeda }} </p>
                </div>
                <hr class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title text-primary">{{ $t('Total') }}:</p>
                  <p class="invoice-total-amount text-primary"> {{ valorTotalPedido.valorLiquido + valorTotalProdutos +
                    valorEntrega | formataMoeda }} </p>
                </div>
              </div>
            </b-col>
          </b-row>

          <hr>

          <div v-if="isEnglish" 
          class="d-flex align-items-center terms-container">
            <b-form-checkbox 
                v-model="permitirConcordar" 
                switch 
                class="terms-checkbox"
            >
              {{ $t('Eu_concordo_com_os') }}
            </b-form-checkbox>
            <b 
                class="text-primary cursor-pointer terms-link" 
                @click="abreModalTermos"
            >
              {{ $t('Termos_de_Servico') }}
            </b>
          </div>



          <!-- Button: GRAVAR E ENVIAR PEDIDO -->
          <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
              variant="primary" 
              class="mb-75" 
              block
              :disabled="submit || !permitirConcordar" 
              @click="gravaPedido"
            >
            {{ $t('Enviar_Pedido') }}
          </b-button>


          <b-progress 
              class="mt-2 progress-bar-success" 
              variant="success" 
              :value="porcentagem" 
              max="100" 
          />
          <span v-if="porcentagem !== 0">
            <strong 
                class="text-success text-center"> 
            {{ porcentagem }} 
          </strong>
          </span>
        </b-card>
      </b-col>

      <ModalLancarPedido 
            ref="modalLancarPedido" 
            :files="files" 
            :pedidoId="pedidoId" 
            :laboratorios="laboratorios"
            :uploadProgress="uploadProgress" 
            @uploadCompleted="uploadCompleted()" 
            @fechouModal="resetFiles" 
        />

      <ModalTermosServico 
          v-if="isEnglish" 
          :nome-laboratorio="nomeLaboSelecionado"
          :permitirConcordar="permitirConcordar" 
          @termosAceitos="handleTermosAceitos" 
      />

    </b-row>

  </section>
</template>

<style lang="scss" scoped>
.terms-container {
  .terms-checkbox {
    margin-right: -0.5rem;

    ::v-deep .custom-control-label {
      margin-right: 0;
    }
  }

  .terms-link {
    font-size: 10px;
    padding: 15px 10px 20px; // Reduz o padding à esquerda
  }
}
</style>

<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";
import Logo from '@core/layouts/components/Logo.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { max } from '@core/utils/validations/validations'
import dataOpcoes from './dataOpcoes'
import Odontograma from "@/views/pedidos/Odontograma"
import OdontogramaCrianca from "@/views/pedidos/OdontogramaCrianca"
import ModalTermosServico from './ModalTermosServico.vue'

import {
  BAlert, BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BCollapse,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSpinbutton,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BPopover,
  BProgress,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Materiais from './Materiais.vue'
import ModalLancarPedido from "@/views/pedidos/ModalLancarPedido.vue";
import ModalMateriaisDropdown from "@/views/pedidos/ModalMateriaisDropdown.vue";

const dadosFormulario = {
  enviar: false,
  conveniado_id: '',
  paciente: '',
  cor_dente: '',
  user_id: '',
  entrega: false,
  retirada: false,
  materiais: [],
  observacoes: [],
  servicos: [
    {
      id: '',
      nome: '',
      categoria_id: '',
      servico_id: '',
      valor: 0,
      data_prazo: '',
      data_prazo_lab: '',
      valorOriginal: 0,
      quantidade: 1,
      tipo_desconto: 0,
      desconto: 0,
      subTotal: 0,
      num_dente: [],
      dente: '',
      cor_dente: '',
      observacao_item: '',
      odontograma: 'odontograma',
      mostrarOdontograma: true,
      produtos: []
    },
  ],
  produtos: [],
}

export default {

  components: {
    ModalMateriaisDropdown,
    BBadge,
    ModalLancarPedido,
    max,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    vSelect,
    Logo,
    BFormSpinbutton,
    BFormFile,
    BProgress,
    BAlert,
    BFormRadio,
    BCollapse,
    BCardHeader,
    ValidationObserver,
    ValidationProvider,
    Odontograma,
    OdontogramaCrianca,
    Materiais,
    ModalTermosServico,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      podeLimparSelectConveniados: true,
      pedidoOs: '',
      permitirConcordar: false,
      ...dataOpcoes,
      mostraDetalhesPedido: false,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      submit: false,
      form: {
        enviar: false,
        conveniado_id: '',
        paciente: '',
        cor_dente: '',
        user_id: '',
        entrega: false,
        retirada: false,
        materiais: [],
        observacoes: [],
        valor_transporte: 0,
        num_os: 0,
        os_ficha_id: 0,
        servicos: [
          {
            id: '',
            nome: '',
            categoria_id: '',
            servico_id: '',
            valor: 0,
            data_prazo: '',
            data_prazo_lab: '',
            valorOriginal: 0,
            quantidade: 1,
            tipo_desconto: 0,
            desconto: 0,
            subTotal: 0,
            subTotalProdutos: 0,
            num_dente: [],
            dente: '',
            cor_dente: '',
            observacao_item: '',
            odontograma: 'odontograma',
            mostrarOdontograma: true,
            produtos: []
          },
        ],
        produtos: [],
      },
      respostaArquivosEnviados: [],
      respostaArquivosNaoEnviados: [],
      produtos: [],
      usuarios: [],
      porcentagem: 0,
      files: [],
      qtdFiles: 0,
      pedidoId: 0,
      uploadProgress: [],
      statusUpload: null
    }
  },
  mounted() {
    this.initTrHeight()

    if (this.$route.params.id > 0) {
      this.buscaDadosServico(this.$route.params.id)
    }
  },
  created() {
    //   window.addEventListener('resize', this.initTrHeight)

    this.obterListaUsuarios()
    if (this.selecionado) {
      this.form.conveniado_id = ''
      this.$store.dispatch('pedidos/listaConveniados', this.selecionado)
      this.$store.dispatch('tabelas/listaTabelas', this.selecionado)
    }
  },
  computed: {
    ...mapState({
      pedidos: state => state.pedidos.pedidos,
      conveniados: state => state.pedidos.conveniados,
      laboratorios: state => state.laboratorios.laboSelecionado,
      tabela: state => state.tabelas.tabelas,
      cliente: state => state.tabelas.clienteConfig,
      materiais: state => state.materiais.materiais,
      user: state => state.user.user,
    }),
    ...mapGetters('laboratorios', ['selecionado', 'nomeLaboSelecionado']),

    filteredConveniados() {

      this.podeLimparSelectConveniados = true

      if (this.user.conveniado_id > 0) {

        this.form.conveniado_id = this.user.conveniado_id
        this.podeLimparSelectConveniados = false
        return this.conveniados.filter(conveniado => conveniado.id === this.user.conveniado_id);

      }

      return this.conveniados;
    },
    categorias() {
      if (this.tabela) {

        let servicos = []
        let produtos = []


        this.tabela.forEach((v) => {

          let categoriaNome = v.nome
          if (v.servicos.length && v.ocultar === 0) {

            v.servicos.forEach((serv, index) => {
              if (index === 0) {
                servicos.push({
                  nome: v.nome,
                  posicao: 0
                })
              }
              if (serv.ocultar === 0) {
                servicos.push({
                  ...serv,
                  posicao: 1,
                  nome: serv.servico_nome
                })
              }
            })
          }

          if (v.cat_produtos.length) {
            v.cat_produtos.forEach((prod) => {
              produtos.push({
                ...prod,
                nome: prod?.produto?.nome,
              })
            })
          }
        })

        return {
          servicos,
          produtos
        }
      }
    },
    valorTotalPedido() {
      let desconto = this.cliente.configuracoes?.desconto
      let valorBruto = this.somaValorBruto()
      let totalDesconto = desconto !== undefined || desconto > 0 ? (valorBruto / 100) * desconto : 0
      let valorLiquido = this.somaValorLiquido()

      return {
        valorBruto,
        totalDesconto,
        valorLiquido
      }
    },
    valorTotalProdutos() {
      let valor = 0;

      this.form.servicos.forEach((v) => {
        valor += v.produtos.reduce((soma, val) => {
          return (soma + parseFloat(val.subTotal))
        }, 0)
      })

      return valor
    },
    valorEntrega() {
      if (!this.form.entrega) return 0; // Retorna 0 se não estiver marcado para entrega

      // Verifica se o cliente tem endereços e se o primeiro endereço tem a propriedade valor_entrega
      if (this.cliente && this.cliente.enderecos && this.cliente.enderecos.length > 0 && this.cliente.enderecos[0].valor_entrega) {
        return parseFloat(this.cliente.enderecos[0].valor_entrega); // Retorna o valor de valor_entrega
      }

      return 0; // Retorna 0 se nenhuma condição for atendida
    },

    isEnglish() {
      if(this.$i18n.locale !== "en") {
        this.permitirConcordar = true
      }
      return this.$i18n.locale === "en"
    },
  },
  watch: {
    laboratorios(newValue, oldvalue) {
      this.form.conveniado_id = ''
      this.$store.dispatch('pedidos/listaConveniados', this.selecionado)
      this.$store.dispatch('tabelas/listaTabelas', this.selecionado)
    },
    valorEntrega(newValor) {
      // Verifica se o novo valor de valorEntrega é válido antes de atualizar form.valor_transporte
      if (!isNaN(newValor)) {
        this.form.valor_transporte = newValor;
      } else {
        this.form.valor_transporte = 0; // Define como 0 caso o valor seja inválido
      }
    },
    files(newValue) {
      this.statusUpload = null
      if (newValue && newValue.length > 30) {
        this.statusUpload = false
      }
    },
    '$i18n.locale'(newLocale){

      this.permitirConcordar = newLocale !== 'en' ? true : false
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    resetFiles() {
      this.files = []
      this.pedidoId = 0
    },
    buscaDadosServico(os_ficha_id) {

      this.$http(`odontodocs/pedidos/consultar/${this.laboratorios}/${os_ficha_id}`).then((res) => {
        this.setaParametrosFicha(res.data)
      }).catch(error => {
        this.getErrors = this.$responseMessages.getMessage(error, null)
      })
    },
    setaParametrosFicha(dados) {
      this.form.paciente = dados.paciente
      this.form.conveniado_id = dados.conveniado_id
    },
    filesUpload(e) {

      this.qtdFiles = 0;
      this.files = [];
      let selectedFiles = e.target.files;

      if (!selectedFiles.length) {
        return false;
      }
      for (let i = 0; i < selectedFiles.length; i++) {
        this.files.push(selectedFiles[i]);
        this.$set(this.uploadProgress, selectedFiles[i].name, 0);

        this.qtdFiles = selectedFiles.length;
      }

    },
    async abreModal() {
      await this.$bvModal.show('modalLancarPedido')
      await this.$refs.modalLancarPedido.handleFilesUpload()
    },
    gravaPedido() {
      this.$refs.formPedidos.validate().then(success => {
        if (success && this.statusUpload !== false) {

          this.submit = true

          if (this.$route.params.id > 0) {
            this.form.os_ficha_id = this.$route.params.id
          }

          this.form.user_id = this.form.user_id ? this.form.user_id.toString() : ''

          const payload = {
            form: this.form
          }

          this.$http.post('odontodocs/pedidos/' + this.laboratorios, payload).then((res) => {

            this.pedidoId = res.data.pedido_id
            this.abreModal()
            this.$refs.materiaisDropdown.limpaMateriaisSubmit()

          }).catch(error => {

            this.getErrors = this.$responseMessages.getMessage(error, null)

          }).finally(() => {
            this.submit = false

          })

        }
      })

    },

    uploadCompleted() {

      const payload = {
        servico: this.form.servicos[0].nome ?? '',
        paciente: this.form.paciente,
        id: this.pedidoId,
        cliente: this.nomeLaboSelecionado,
        retirada: this.form.retirada
      }

      this.$http.post('odontodocs/pedidos/enviar/' + this.laboratorios, payload).then((res) => {
        if (res.status === 200) {
          this.limparCampos()
          this.$refs.formPedidos.reset()
        }
      }).catch(error => {
        console.log('envia pedidos erro', error)
      })

    },

    // SERVIÇO
    mudouServico(index, val) {

      if (val == null) {
        this.form.servicos.splice(index, 1, this.arrayServicos())
      } else {

        let desconto = this.cliente.configuracoes.desconto
        this.form.servicos[index].id = val.id
        this.form.servicos[index].nome = val.nome
        this.form.servicos[index].categoria_id = val.categoria_id
        this.form.servicos[index].servico_id = val.id
        this.form.servicos[index].valor = val.valor
        this.form.servicos[index].quantidade = 1
        this.form.servicos[index].valorOriginal = val.valor
        this.form.servicos[index].desconto = desconto
        this.form.servicos[index].subTotal = this.calculaSubtotal(1, val.valor)
        this.configuraDataPrazo(index, val.dias_prazo)
      }

    },
    arrayServicos() {
      return {
        id: 0,
        nome: '',
        categoria_id: '',
        valor: 0,
        data_prazo: '',
        data_prazo_lab: '',
        valorOriginal: 0,
        quantidade: 1,
        tipo_desconto: 0,
        desconto: 0,
        subTotal: 0,
        num_dente: '',
        dente: '',
        cor_dente: '',
        observacao_item: '',
        odontograma: 'odontograma',
        mostrarOdontograma: true,
      }
    },
    arrayProdutos() {
      return {
        id: '',
        nome: '',
        quantidade: 1,
        valor: 0,
        valorOriginal: 0,
        subTotal: 0,
        categoria_id: ''
      }
    },
    somaValorLiquido() {
      return this.form.servicos.reduce((soma, val) => {
        return (soma + parseFloat(val.subTotal))
      }, 0)
    },
    somaValorBruto() {
      return this.form.servicos.reduce((soma, val) => {
        return (soma + parseFloat(val.valorOriginal) * val.quantidade)
      }, 0)
    },
    calculaSubtotal(quantidade, valor) {
      let subtotal = valor * quantidade
      let desconto = this.cliente.configuracoes.desconto

      if (desconto > 0) {
        let desc = (subtotal / 100) * desconto
        subtotal = subtotal - desc
        subtotal = subtotal - desc
      }

      return subtotal
    },

    calculaSubtotalProdutos(produtos) {

      return produtos.reduce((soma, prod) => {
        return soma + parseFloat(prod.subTotal);
      }, 0);

    },

    alterouQuantidade(index, quantidade) {
      let valor = this.form.servicos[index].valorOriginal
      this.form.servicos[index].valor = valor
      this.form.servicos[index].subTotal = this.calculaSubtotal(quantidade, valor)
    },
    addNewItemInItemForm() {

      let index = this.form.servicos.length - 1

      this.form.servicos.push({
        id: '',
        nome: '',
        categoria_id: '',
        valor: 0,
        data_prazo: '',
        data_prazo_lab: '',
        valorOriginal: 0,
        quantidade: 1,
        tipo_desconto: 0,
        desconto: 0,
        subTotal: 0,
        subTotalProdutos: 0,
        num_dente: '',
        dente: '',
        cor_dente: '',
        observacao_item: '',
        odontograma: 'odontograma',
        mostrarOdontograma: true,
        produtos: []
      })

      this.$root.$emit('bv::toggle::collapse', 'collapse-' + index)

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {

        }, 350)
      })
    },
    removeItem(index) {
      this.form.servicos.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    // PRODUTO
    mudouProduto(index, indexProd, val) {

      if (val == null) {
        this.form.servicos[index].produtos.splice(indexProd, 1, this.arrayProdutos())
      } else {
        this.form.servicos[index].produtos[indexProd].id = val.produto_id
        this.form.servicos[index].produtos[indexProd].categoria_id = val.categoria_id
        this.form.servicos[index].produtos[indexProd].nome = val.nome
        this.form.servicos[index].produtos[indexProd].valor = val.valor ?? 0
        this.form.servicos[index].produtos[indexProd].quantidade = 1
        this.form.servicos[index].produtos[indexProd].valorOriginal = val.valor ?? 0
        this.form.servicos[index].produtos[indexProd].subTotal = val.valor ?? 0

        this.form.servicos[index].subTotalProdutos = this.calculaSubtotalProdutos(this.form.servicos[index].produtos)

      }

    },
    alterouQuantidadeProduto(index, indexProd, quantidade) {
      let valor = this.form.servicos[index].produtos[indexProd].valorOriginal
      this.form.servicos[index].produtos[indexProd].valor = valor
      this.form.servicos[index].produtos[indexProd].subTotal = valor * quantidade

      this.form.servicos[index].subTotalProdutos = this.calculaSubtotalProdutos(this.form.servicos[index].produtos)
    },
    excluirProduto(index, indexProd) {
      this.form.servicos[index].produtos.splice(indexProd, 1)
      this.form.servicos[index].subTotalProdutos = this.calculaSubtotalProdutos(this.form.servicos[index].produtos)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    addNewProductInItemForm(index) {
      this.form.servicos[index].produtos.push(
        {
          id: '',
          nome: '',
          quantidade: 1,
          valor: 0,
          valorOriginal: 0,
          subTotal: 0,
          categoria_id: ''
        }
      )
    },

    // OUTROS
    configuraDataPrazo(index, dias) {
      if (dias > 0) {
        this.form.servicos[index].data_prazo = this.$getDate.addDias(null, dias)
        this.form.servicos[index].data_prazo_lab = this.$getDate.addDias(null, dias - 1)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    obterListaUsuarios() {
      this.$http.get('odontodocs/usuarios').then(res => {
        this.usuarios = res.data
      })
    },
    limparCampos() {
      this.$refs.odontogramaComponente[0].desativaTodos()
      this.form = {
        enviar: false,
        conveniado_id: '',
        paciente: '',
        cor_dente: '',
        user_id: '',
        num_os: 0,
        os_ficha_id: 0,
        entrega: false,
        retirada: false,
        materiais: [],
        observacoes: [],
        servicos: [
          {
            id: '',
            nome: '',
            categoria_id: '',
            servico_id: '',
            valor: 0,
            data_prazo: '',
            data_prazo_lab: '',
            valorOriginal: 0,
            quantidade: 1,
            tipo_desconto: 0,
            desconto: 0,
            subTotal: 0,
            num_dente: [],
            dente: '',
            cor_dente: '',
            observacao_item: '',
            odontograma: 'odontograma',
            mostrarOdontograma: true,
            produtos: []
          },
        ],
        produtos: [],
      }
    },

    abreModalTermos() {
      this.$bvModal.show('modalTermos')
    },

    handleTermosAceitos(aceito) {
      this.permitirConcordar = aceito
    }

  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return '0,00'

      return valor.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    convertData(data) {
      if (!data) return ''

      return moment(data).format('DD/MM/YYYY')

    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
