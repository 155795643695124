import i18n from '@/libs/i18n'

export default {

  formas: [
    { value: "", text: i18n.t("Forma_Pagamento") },
    { value: "boleto", text: i18n.t("Boleto_Bancario") },
    { value: "boleto_externo", text: i18n.t("Boleto_Bancario") },
    { value: "credito", text: i18n.t("Cartao_de_Credito") },
    { value: "debito", text: i18n.t("Cartao_de_Debito") },
    { value: "cheque", text: i18n.t("Cheque") },
    { value: "deposito", text: i18n.t("Deposito_Bancario") },
    { value: "dinheiro", text: i18n.t("Dinheiro") },
    { value: "pix", text: i18n.t("Pix") },
    { value: "pix_externo", text: i18n.t("Pix") },
    { value: "transferencia", text: i18n.t("Transferencia_Bancaria") },
    { value: "outros", text: i18n.t("Outros") },
  ],

  formasAgrupadas:
    {
      'null': i18n.t('Nao_Informado'),
      boleto: i18n.t("Boleto_Bancario"),
      boleto_externo: i18n.t("Boleto_Bancario"),
      credito: i18n.t("Cartao_de_Credito"),
      debito: i18n.t("Cartao_de_Debito"),
      cheque: i18n.t("Cheque"),
      deposito: i18n.t("Deposito_Bancario"),
      dinheiro: i18n.t("Dinheiro"),
      pix: i18n.t("Pix"),
      pix_externo: i18n.t("Pix"),
      transferencia: i18n.t("Transferencia_Bancaria"),
      outros: i18n.t("Outros"),
    },

  formasEtiquetas :
    {
      'null': 'light-secondary',
      dinheiro: 'light-primary',
      outros: 'light-secondary',
      credito: 'light-success',
      debito: 'light-success',
      cheque: 'light-danger',
      boleto: 'light-warning',
      boleto_externo: 'light-warning',
      deposito: 'light-info',
      transferencia: 'light-info',
      pix: 'light-info',
      pix_externo: 'light-info',
    }


}
