import Guard from './../services/auth'
import i18n from './../libs/i18n'

export default [
  {
    path: '/tabela',
    name: 'tabela',
    component: () => import('@/views/tabela/TabelaPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: i18n.t('Tabela'),
      owner: false
    },
  },
]
