<template>

  <div class="odontograma">
   <div class="odontograma-sup">
      <div class="odontograma-dente" @click="superior = !superior, alteraTodos(1, superior, inferior)">
        <div class="odontogram-number ">
          <b-button :variant="superior === true ? 'gradient-success' : 'secondary'" size="sm"
                    style="padding: 4px;position: relative;top: -5px">{{ $t('SUP') }}
          </b-button>
        </div>
      </div>

      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente55 = !imagemDentesSup.dente55 : '', alteraDente(55, imagemDentesSup.dente55)">
        <div class="odontograma-dente-imagem dente-17 tooltipstered">
          <img v-if="!imagemDentesSup.dente55" :width="26" src="../../assets/images/dentes/dente-17.png">
          <img v-else :width="26" src="../../assets/images/dentes/dente-17-selecionado.png">
        </div>
        <div class="odontogram-number odontogram-number-17">
          {{ $t('Dente') }} 55
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente54 = !imagemDentesSup.dente54 : '', alteraDente(54, imagemDentesSup.dente54)">
        <div class="odontograma-dente-imagem dente-16 odontogram-tooth-done tooltipstered">
          <img v-if="!imagemDentesSup.dente54" src="../../assets/images/dentes/dente-16.png" width="28">
          <img v-else src="../../assets/images/dentes/dente-16-selecionado.png" width="28">
        </div>
        <div class="odontogram-number odontogram-number-16">
          54
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente53 = !imagemDentesSup.dente53 : '', alteraDente(53, imagemDentesSup.dente53)">
        <div class="odontograma-dente-imagem dente-15 tooltipstered">
          <img v-if="!imagemDentesSup.dente53" src="../../assets/images/dentes/dente-15.png" width="18">
          <img v-else src="../../assets/images/dentes/dente-15-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-15">
          53
        </div>
      </div>

      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente52 = !imagemDentesSup.dente52 : '', alteraDente(52, imagemDentesSup.dente52)">
        <div class="odontograma-dente-imagem dente-12 tooltipstered">
          <img v-if="!imagemDentesSup.dente52" src="../../assets/images/dentes/dente-12.png" width="18">
          <img v-else src="../../assets/images/dentes/dente-12-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-12">
          52
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente51 = !imagemDentesSup.dente51 : '', alteraDente(51, imagemDentesSup.dente51)">
        <div class="odontograma-dente-imagem dente-11 tooltipstered">
          <img v-if="!imagemDentesSup.dente51" src="../../assets/images/dentes/dente-11.png" width="20">
          <img v-else src="../../assets/images/dentes/dente-11-selecionado.png" width="20">
        </div>
        <div class="odontogram-number odontogram-number-11">
          51
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente61 = !imagemDentesSup.dente61 : '', alteraDente(61, imagemDentesSup.dente61)">
        <div class="odontograma-dente-imagem dente-21 tooltipstered">
          <img v-if="!imagemDentesSup.dente61" src="../../assets/images/dentes/dente-21.png" width="20">
          <img v-else src="../../assets/images/dentes/dente-21-selecionado.png" width="20">
        </div>
        <div class="odontogram-number odontogram-number-21">
          61
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente62 = !imagemDentesSup.dente62 : '', alteraDente(62, imagemDentesSup.dente62)">
        <div class="odontograma-dente-imagem dente-22 tooltipstered">
          <img v-if="!imagemDentesSup.dente62" src="../../assets/images/dentes/dente-22.png" width="18">
          <img v-else src="../../assets/images/dentes/dente-22-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-22">
          62
        </div>
      </div>

      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente63 = !imagemDentesSup.dente63 : '', alteraDente(63, imagemDentesSup.dente63)">
        <div class="odontograma-dente-imagem dente-25 tooltipstered">
          <img v-if="!imagemDentesSup.dente63" src="../../assets/images/dentes/dente-25.png" width="20">
          <img v-else src="../../assets/images/dentes/dente-25-selecionado.png" width="20">
        </div>
        <div class="odontogram-number odontogram-number-25">
          63
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente64= !imagemDentesSup.dente64 : '', alteraDente(64, imagemDentesSup.dente64)">
        <div class="odontograma-dente-imagem dente-26 odontogram-tooth-on-process tooltipstered">
          <img v-if="!imagemDentesSup.dente64" src="../../assets/images/dentes/dente-26.png" width="24">
          <img v-else src="../../assets/images/dentes/dente-26-selecionado.png" width="24">
        </div>
        <div class="odontogram-number odontogram-number-26">
          64
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente65 = !imagemDentesSup.dente65 : '', alteraDente(65, imagemDentesSup.dente65)">
        <div class="odontograma-dente-imagem dente-27 tooltipstered">
          <img v-if="!imagemDentesSup.dente65" src="../../assets/images/dentes/dente-27.png" width="28">
          <img v-else src="../../assets/images/dentes/dente-27-selecionado.png" width="28">
        </div>
        <div class="odontogram-number odontogram-number-27">
          65
        </div>
      </div>

    </div>
    <div class="odontograma-inf">
      <div class="odontograma-dente" @click="inferior = !inferior, alteraTodos(2, superior, inferior)">
        <div class="odontogram-number ">
          <b-button :variant="inferior === true ? 'gradient-success' : 'secondary'" active size="sm"
                    style="padding: 4px;position: relative;top: -40px;left: -4px">{{ $t('INF') }}
          </b-button>
        </div>
      </div>

      <div class="odontograma-dente-inf odontograma-dente-inf-47"
           @click="!inferior ? imagemDentesInf.dente85 = !imagemDentesInf.dente85 : '', alteraDente(85, imagemDentesInf.dente85)">
        <div class="odontogram-number odontogram-number-47">
          85
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-47 tooltipstered">
          <img v-if="!imagemDentesInf.dente85" src="../../assets/images/dentes/dente-47.png" width="25">
          <img v-else src="../../assets/images/dentes/dente-47-selecionado.png" width="25">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-46"
           @click="!inferior ? imagemDentesInf.dente84 = !imagemDentesInf.dente84 : '', alteraDente(84, imagemDentesInf.dente84)">
        <div class="odontogram-number odontogram-number-46">
          84
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-46 tooltipstered">
          <img v-if="!imagemDentesInf.dente84" src="../../assets/images/dentes/dente-46.png" width="28">
          <img v-else src="../../assets/images/dentes/dente-46-selecionado.png" width="28">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-45"
           @click="!inferior ? imagemDentesInf.dente83 = !imagemDentesInf.dente83 : '', alteraDente(83, imagemDentesInf.dente83)">
        <div class="odontogram-number odontogram-number-45">
          83
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-45 tooltipstered">
          <img v-if="!imagemDentesInf.dente83" src="../../assets/images/dentes/dente-45.png" width="20">
          <img v-else src="../../assets/images/dentes/dente-45-selecionado.png" width="20">
        </div>
      </div>

      <div class="odontograma-dente-inf odontograma-dente-inf-42" style="margin-left: -3px"
           @click="!inferior ? imagemDentesInf.dente82 = !imagemDentesInf.dente82 : '', alteraDente(82, imagemDentesInf.dente82)">
        <div class="odontogram-number odontogram-number-42">
          82
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-42 tooltipstered">
          <img v-if="!imagemDentesInf.dente82" src="../../assets/images/dentes/dente-42.png" width="16">
          <img v-else src="../../assets/images/dentes/dente-42-selecionado.png" width="16">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-41"
           @click="!inferior ? imagemDentesInf.dente81 = !imagemDentesInf.dente81 : '', alteraDente(81, imagemDentesInf.dente81)">
        <div class="odontogram-number odontogram-number-41">
          81
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-41 tooltipstered">
          <img v-if="!imagemDentesInf.dente81" src="../../assets/images/dentes/dente-41.png" width="15">
          <img v-else src="../../assets/images/dentes/dente-41-selecionado.png" width="15">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-31"
           @click="!inferior ? imagemDentesInf.dente71 = !imagemDentesInf.dente71 : '', alteraDente(71, imagemDentesInf.dente71)">
        <div class="odontogram-number odontogram-number-31">
          71
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-31 tooltipstered">
          <img v-if="!imagemDentesInf.dente71" src="../../assets/images/dentes/dente-31.png" width="15">
          <img v-else src="../../assets/images/dentes/dente-31-selecionado.png" width="15">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-32"
           @click="!inferior ? imagemDentesInf.dente72 = !imagemDentesInf.dente72 : '', alteraDente(72, imagemDentesInf.dente72)">
        <div class="odontogram-number odontogram-number-32">
          72
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-32 tooltipstered">
          <img v-if="!imagemDentesInf.dente72" src="../../assets/images/dentes/dente-32.png" width="16">
          <img v-else src="../../assets/images/dentes/dente-32-selecionado.png" width="16">
        </div>
      </div>

      <div class="odontograma-dente-inf odontograma-dente-inf-35"
           @click="!inferior ? imagemDentesInf.dente73 = !imagemDentesInf.dente73 : '', alteraDente(73, imagemDentesInf.dente73)">
        <div class="odontogram-number odontogram-number-35">
          73
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-35 tooltipstered">
          <img v-if="!imagemDentesInf.dente73" src="../../assets/images/dentes/dente-35.png" width="20">
          <img v-else src="../../assets/images/dentes/dente-35-selecionado.png" width="20">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-36"
           @click="!inferior ? imagemDentesInf.dente74 = !imagemDentesInf.dente74 : '', alteraDente(74, imagemDentesInf.dente74)">
        <div class="odontogram-number odontogram-number-36">
          74
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-36 tooltipstered">
          <img v-if="!imagemDentesInf.dente74" src="../../assets/images/dentes/dente-36.png" width="28">
          <img v-else src="../../assets/images/dentes/dente-36-selecionado.png" width="28">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-37"
           @click="!inferior ? imagemDentesInf.dente75 = !imagemDentesInf.dente75 : '', alteraDente(75, imagemDentesInf.dente75)">
        <div class="odontogram-number odontogram-number-37">
          75
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-37 tooltipstered">
          <img v-if="!imagemDentesInf.dente75" src="../../assets/images/dentes/dente-37.png" width="27">
          <img v-else src="../../assets/images/dentes/dente-37-selecionado.png" width="27">
        </div>
      </div>

    </div>

  </div>

</template>

<script>

import {BButton} from 'bootstrap-vue'

export default {

  props: ['dentesSelecionados'],

  components: {
    BButton,
  },


  data() {
    return {
      tipoArcada: 1,
      tamanhoDentesImg: 28,
      imagemDentesSup: {
        dente55: false,
        dente54: false,
        dente53: false,
        dente52: false,
        dente51: false,
        dente61: false,
        dente62: false,
        dente63: false,
        dente64: false,
        dente65: false,

      },
      imagemDentesInf: {
        dente85: false,
        dente84: false,
        dente83: false,
        dente82: false,
        dente81: false,
        dente71: false,
        dente72: false,
        dente73: false,
        dente74: false,
        dente75: false,
      },
      superior: false,
      inferior: false,
      dentesSelInf: [],
      dentesSelSup: []

    }
  },

  methods: {

    /**
     * @var dente
     * 1 - Superior
     * 2 - inferior
     * **/

    alteraTodos(dente, superior, inferior) {
      let resultado = []

      if (dente === 1) {

        this.dentesSelSup = []

        if (superior) {

          this.selecionaDentesSup()
          this.dentesSelSup = ['SUP']

          resultado = [
            'SUP',
            ...this.dentesSelInf
          ]

        } else {

          this.desativaDentesSup()
          this.dentesSelSup = []
          resultado = this.dentesSelInf

        }


      } else {

        this.dentesSelInf = []

        if (inferior) {

          this.selecionaDentesInf()

          resultado = [
            'INF',
            ...this.dentesSelSup
          ]

          this.dentesSelInf = ['INF']

        } else {
          this.desativaDentesInf()
          resultado = this.dentesSelSup
          this.dentesSelInf = []

        }

      }

      this.$emit('denteSelecionado', resultado)
    },
    alteraDente(numDente, selecionado) {

      // Adiciona na Lista

      let index = this.dentesSelecionados.indexOf(numDente)

      if (selecionado) {
        if (index === -1)
          if (numDente > 65) {
            if (!this.inferior) {

              this.dentesSelInf.push(numDente)
            }
          } else {
            if (!this.superior) {
              this.dentesSelSup.push(numDente)
            }
          }
      } else {
        if (numDente > 65) {
          if (!this.inferior) {

            let indexInf = this.dentesSelInf.indexOf(numDente)
            this.dentesSelInf.splice(indexInf, 1)
          }
        } else {
          if (!this.superior) {

            let indexSup = this.dentesSelSup.indexOf(numDente)
            this.dentesSelSup.splice(indexSup, 1)
          }
        }

      }


      let result =
          [
            ...this.dentesSelInf,
            ...this.dentesSelSup
          ]


      this.$emit('denteSelecionado', result.sort())

    },
    selecionaDentesEditar(){

      // ao editar uma entrada, roda a  funcao para setar dentes superiores e inferiores

      this.desativaDentesInf()
      this.desativaDentesSup()
      this.dentesSelSup = []
      this.dentesSelInf =[]
      let dentes = this.dentesSelecionados

      if(typeof this.dentesSelecionados == 'string'){
        dentes = this.dentesSelecionados?.split(',')
      }

      if(dentes) {
        dentes.forEach( (v) => {
          if(v <= 65) {
            this.imagemDentesSup['dente'+v] = true

            this.dentesSelSup.push(parseInt(v))
          }else if(v > 65 || v <= 85){
            this.imagemDentesInf['dente'+v] = true
            this.dentesSelInf.push(parseInt(v))
          }else if(v == 'INF'){
            this.selecionaDentesInf()
            this.inferior = true
            this.dentesSelInf = ["INF"]
          }
          else if(v == 'SUP'){
            this.selecionaDentesSup()
            this.superior = true
            this.dentesSelSup = ["SUP"]
          }

        } )
      }

    },

    selecionaDentesInf() {
      this.imagemDentesInf = {
        dente85: true,
        dente84: true,
        dente83: true,
        dente82: true,
        dente81: true,
        dente71: true,
        dente72: true,
        dente73: true,
        dente74: true,
        dente75: true,
      }
    },
    selecionaDentesSup() {
      this.imagemDentesSup = {
        dente55: true,
        dente54: true,
        dente53: true,
        dente52: true,
        dente51: true,
        dente61: true,
        dente62: true,
        dente63: true,
        dente64: true,
        dente65: true,
      }
    },
    desativaDentesInf() {
      this.imagemDentesInf = {
        dente85: false,
        dente84: false,
        dente83: false,
        dente82: false,
        dente81: false,
        dente71: false,
        dente72: false,
        dente73: false,
        dente74: false,
        dente75: false,
      }
    },
    desativaDentesSup() {
      this.imagemDentesSup = {
        dente55: false,
        dente54: false,
        dente53: false,
        dente52: false,
        dente51: false,
        dente61: false,
        dente62: false,
        dente63: false,
        dente64: false,
        dente65: false,
      }
    },
    desativaTodos() {
      this.dentesSelInf = []
      this.dentesSelSup = []
      this.inferior = false
      this.superior = false

      this.imagemDentesSup = {
        dente55: false,
        dente54: false,
        dente53: false,
        dente52: false,
        dente51: false,
        dente61: false,
        dente62: false,
        dente63: false,
        dente64: false,
        dente65: false,

      }
      this.imagemDentesInf = {
        dente85: false,
        dente84: false,
        dente83: false,
        dente82: false,
        dente81: false,
        dente71: false,
        dente72: false,
        dente73: false,
        dente74: false,
        dente75: false,
      }

    }

  }


}

</script>

<style scoped>

.odontograma {
  width: 350px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 10px;

}

.odontograma-sup {
  border-bottom: dashed 1px #c5c5c5;
}

.odontograma-inf {
  margin-top: 0;
  padding: 4px;
}

.odontograma-dente {
  display: inline-block;
}

.odontograma-dente-inf {
  display: inline-block;
}

.odontogram-top-teeth .odontogram-number {
  margin-top: -5px;
  margin-bottom: -5px;
}

.odontogram-number {
  text-align: center;
  cursor: pointer;


}

.odontogram-number-48 {
  margin-bottom: 5px;
}

.odontogram-number-47 {
  margin-bottom: 5px;
}

.odontogram-number-46 {
  margin-bottom: 6px;
}

.odontogram-number-45 {
  margin-bottom: 1px;
}

.odontogram-number-44 {
  margin-bottom: 4px;
}

.odontogram-number-43 {
  margin-bottom: 4px;
}

.odontogram-number-42 {
  margin-bottom: 4px;
}

.odontogram-number-41 {
  margin-bottom: 2px;
}

.odontogram-number-31 {
  margin-bottom: 4px;
}

.odontogram-number-32 {
  margin-bottom: 4px;
}

.odontogram-number-33 {
  margin-bottom: 5px;
}

.odontogram-number-34 {
  margin-bottom: 4px;
}

.odontogram-number-35 {
  margin-bottom: 2px;
}

.odontogram-number-36 {
  margin-bottom: 5px;
}

.odontogram-number-37 {
  margin-bottom: 6px;
}

.odontogram-number-38 {
  margin-bottom: 5px;
  text-align: center;
}

img {
  margin: 0 2px 0 2px;
  cursor: pointer;
}

.odontograma-dente-inf-48 {
  margin-left: -6px;
}

.odontograma-dente-inf-47 {
  margin-left: -2px;
}

.odontograma-dente-inf-41 {
  padding-left: 5px;
}


</style>
