<template>
  <b-modal id="chat" size="xl" hide-footer>
    <b-card no-body style="border: 1px solid #ebe9f1">
      <div id="app">
        <section class="section">
          <div class="container">
            <!-- <div class="columns janela-principal"> -->

            <!-- Coluna Esquerda -->
            <b-row>
              <b-col class="m-0 p-0" md="4">
                <div class="column is-3 lista-de-conversas">
                  <b-row class="barra-superior-esquerda">
                    <div class="d-flex align-items-center w-100 px-1">
                      <b-input-group class="input-group-merge w-100">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SearchIcon" class="text-muted" />
                        </b-input-group-prepend>
                        <b-form-input :placeholder=" $t('Adicionar_Servico_Num_OS_ou_Paciente')" id="buscarOS" type="text" v-model="campoBuscar" debounce="800" @update="buscar"/>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row style="border-bottom: 1px solid rgb(235, 233, 241);" class="px-1">
                    <b-col cols="12">
                      <b-form-group class="mt-1">
                        <label for="buscarOs">{{ $t('Filtrar_Conversas_Laboratorio') }}</label>
                        <v-select
                            v-model="laboratorioId"
                            :options="listaLaboratorios"
                            :reduce="laboratorio => laboratorio.id"
                            label="nome"
                        >
                          <div slot="no-options">{{ $t('Laboratorio_nao_encontrado') }}</div>
                        </v-select>

                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="lista">
                    <item-lista
                        style="height: 67px; border-bottom: 1px solid rgb(235, 233, 241);"
                        v-for="(conversa, indice) in conversasFiltradas"
                        :key="indice"
                        :mensagemAtiva="conversa.os_item_id === selecionado"
                        :nomeDoUsuario="conversa.servico_nome"
                        :avatar="conversa.num_os.toString()"
                        :laboratorio="conversa.laboratorio"
                        :novaMensagem="conversa.nova_mensagem"
                        :ultimaMensagem="conversa.paciente ? conversa.paciente : ''"
                        @click.native="buscaMensagensSelecionada(conversa), indiceAtivo = indice"
                    />
                  </div>
                </div>
              </b-col>

              <!-- Coluna Direita -->
              <b-col class="m-0 p-0" md="8">
                <div class="column conversa-ativa">
                  <!-- 1. Barra Superior -->
                  <div class="barra-superior-direita pl-2">
                    <h6 class="pt-2" v-if="nomeServicoSelecionado">{{ nomeServicoSelecionado }}</h6>
                  </div>
                  <!-- 2. Mensagens -->
                  <div class="lista-mensagens" id="view-scroll" ref="messages" v-if="selecionado > 0">
                    <mensagem
                        class="item"
                        :conteudo="mensagem.mensagem"
                        :horario="mensagem.data | filtroData"
                        :verde="mensagem.para_labo ? true : false"
                        v-for="(mensagem, indice_) in mensagens"
                        :key="indice_"
                    />
                  </div>
                  <!-- 3. Barra Inferior -->
                  <div class="barra-inferior" v-if="selecionado > 0">
                    <b-row>
                      <b-col class="pr-0">
                        <b-form-input
                            v-on:keyup.enter="enviarMensagem"
                            placeholder="Mensagem"
                            v-model="conteudoASerEnviado"
                        />
                      </b-col>
                      <b-col cols="12" md="auto">
                        <b-button
                            class=""
                            variant="primary"
                            @click="enviarMensagem"
                            :disabled="conteudoASerEnviado == ''"
                        >Enviar</b-button
                        >
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>

            <!-- </div> -->
          </div>
        </section>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BAvatar,
  BButton,
  BImg,
} from "bootstrap-vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ItemLista from "./ItemDaListaDeConversa.vue";
import Mensagem from "./MensagemDaConversaAtiva.vue";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BAvatar,
    BButton,
    BImg,
    ItemLista,
    Mensagem,
    VuePerfectScrollbar,
    vSelect,
  },
  name: "App",
  data: function () {
    return {
      //conversas: conversasIniciais,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      campoBuscar: '',
      indiceAtivo: 0,
      conteudoASerEnviado: "",
      laboratorioId: 0,
    }
  },

  computed: {
    ...mapState({
      conversas: state => state.chat.listaServicosChat,
      selecionado: state => state.chat.selecionado,
      laboratorios: state => state.laboratorios.laboSelecionado,
      listaLaboratorios: state => state.laboratorios.laboratorios,
      mensagens: state => state.chat.mensagens,
      arraySelecionado: state => state.chat.arraySelecionado,
      user: state => state.user.user
    }),
    ...mapGetters("chat",["nomeServicoSelecionado", "listaFiltros"]),

    conversasFiltradas() {

      if(this.laboratorioId > 0) {
        return this.listaFiltros.filter( v => Number(v.id_company) === Number(this.laboratorioId))
      }
      return this.listaFiltros

    }

  },

  watch: {
    mensagens() {
      this.ajustaScroll()
    },
  },

  methods: {
    buscar() {
      let buscar = !isNaN(this.campoBuscar) ? 'num_os='+ this.campoBuscar : 'paciente='+this.campoBuscar.toLowerCase()

      if (!this.campoBuscar.length) {
        this.$store.commit('chat/STORE_LISTA_FILTRADA', [] )
        this.$store.commit('chat/STORE_CAMPO_BUSCAR', false )
      } else {
        this.$store.commit('chat/STORE_CAMPO_BUSCAR', true )
        this.$http.get("odontodocs/chat/buscar/" + this.laboratorios+'?'+buscar)
            .then((res) => {

              this.$store.commit('chat/STORE_LISTA_FILTRADA', res.data )

            })
            .catch((error) => {
              //   this.getErrors = this.$responseMessages.getMessage(error, null);
            })
            .finally(() => {
              //  this.submit = false;
            });
      }
    },
    ajustaScroll() {
      setTimeout( ()=> {
        this.$refs.messages.scrollTo(0, this.$refs.messages.scrollHeight)
      }, 50)
    },
    adicionaNaLista(serv) {
      if(this.conversas.length) {
        let existeItem = this.conversas.find( v => v.os_item_id === serv.items_id)

        if(existeItem == undefined) {

          let item = {
            data_lancamento: moment(Date()).format('YYYY-MM-DD H:mm:ss'),
            nova_mensagem: true,
            id_company: this.laboratorios,
            num_os: serv.num_os,
            os_item_id: serv.items_id,
            paciente: serv.paciente,
            servico_nome: serv.servico_nome,
            tem_mensagem: true,
          }

          this.$store.commit('chat/ADD_LISTA_SERVICOS', item )

        }

      }
    },
    enviarMensagem() {
      if (this.conteudoASerEnviado) {

        let payload = {
          mensagem: {
            data: moment(Date()).format('YYYY-MM-DD H:mm:ss'),
            id_company: this.arraySelecionado.id_company,
            mensagem: this.conteudoASerEnviado,
            os_item_id: this.selecionado,
            para_labo: 1,
            para_docs: 0,
            nova_mensagem: true,
            tem_mensagem: true,
          },
          item: {
            ...this.arraySelecionado,
            data_lancamento: moment(Date()).format('YYYY-MM-DD H:mm:ss'),
            os_item_id: this.selecionado,
            nova_mensagem: true,
            tem_mensagem: true,
          }
        }
        this.$http.post('odontodocs/chat/'+this.arraySelecionado.id_company, payload).then( (res) => {
          this.$store.commit('chat/ADD_MENSAGENS', payload)

          let arraySelecionado = {
            ...this.arraySelecionado,
            items_id: this.arraySelecionado.os_item_id
          }
          this.adicionaNaLista(arraySelecionado)
        })

        this.conteudoASerEnviado = ''

      }
    },

    async buscaMensagensSelecionada(serv) {
      this.$store.commit('chat/STORE_SELECIONADO', serv.os_item_id)
      this.$store.commit('chat/SETAR_LISTA_SEVICOS_LIDA', serv.os_item_id)
      this.$store.commit('chat/STORE_ARRAY_SELECIONADO', serv)

      let payload = {
        id_company: this.laboratorios,
        os_item_id: serv.os_item_id,
      }
      await this.$store.dispatch('chat/listaMensagens', payload)
    }

  },

  filters: {
    filtroData(data) {
      if(!data) return
      return moment(data).format('DD/MM/YYYY H:mm')
    }
  }
};
</script>

<style>
/* Barra Superior */
.barra-superior-esquerda {
  margin: 0;
  height: 60px;
  padding-top: 5px;
  background: white;
  border-bottom: 1px solid rgb(235, 233, 241);
}

.barra-superior-direita {
  margin: 0;
  height: 60px;
  background: white;
  border-left: 1px solid rgb(235, 233, 241);
  border-bottom: 1px solid rgb(235, 233, 241);
}

.lista {
  height: 440px;
  overflow-y: scroll;
}
/* Coluna Esquerda */
.lista-de-conversas {
  padding: 0;
  background: white;
}
/* Coluna Direita */
.conversa-ativa {
  padding: 0;
  background-color: #f0f2f7;
  position: relative;
}
.lista-mensagens {
  padding-bottom: 77px;
  height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-size: 100px;
  background-image: url('./dentes.jpg');
}
.lista-mensagens .item:first-child {
  margin-top: auto;
}
/* Barra Inferior */
.barra-inferior {
  bottom: 0;
  width: 100%;
  padding: 15px;
  position: absolute;
  background: white;
  border-left: 1px solid rgb(235, 233, 241);
}
</style>
