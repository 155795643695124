import i18n from "@/libs/i18n";


export default [
  {
    title: i18n.t('Inicio'),
    route: 'home',
    icon: 'HomeIcon',
    acesso: ''
  },
  {
    title: i18n.t('Pedidos'),
    route: 'pedidos',
    icon: 'TrelloIcon',
    acesso: ''
  },
  {
    title: i18n.t('Financeiro'),
    route: 'financeiro',
    icon: 'DollarSignIcon',
    acesso: ''
  },
  {
    title: i18n.t('Tabela_de_Precos'),
    route: 'tabela',
    icon: 'FileTextIcon',
    acesso: ''
  },
  {
    title: i18n.t('Usuarios'),
    route: 'usuarios',
    icon: 'UsersIcon',
    acesso: ''
  },
]
