<template>
  <b-modal
    id="modalEntregues"
    hide-footer
    centered
    size="xl"
    scrollable
    :title=" $t('Servicos') "
  >
    <b-row class="my-1">
      <b-col md="6" sm="8" offset-md="6" class="float-right">
        <b-form-group
          label-cols-sm="3"
          label-align-sm="right"
          label-size="lg"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder=" $t('Procurar') "
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t('Limpar') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      hover
      responsive
      sticky-header
      :items="dados"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(data_lancamento)="data">
        {{ data.item.data_lancamento | converteData }}
      </template>

      <template #cell(data_prazo)="data">
        {{ data.item.data_prazo | converteData }}
      </template>

      <template #cell(servico_nome)="data">
        {{ data.item.servico_nome }}
        <strong class="text-primary"> {{ data.item.num_dente }}</strong>
      </template>

      <template #cell(situacao)="data">
        <b-badge
          variant="light-success"   
        >
          {{ data.item.situacao | traduzSituacao }}
        </b-badge>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import formataData from "@/mixins/formataData";
import {
  BModal,
  BTable,
  BBadge,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";

import moment from "moment";
import traducaoSituacao from "@/services/traducaoSituacao";

export default {
  mixins : [formataData],

  components: {
    BModal,
    BTable,
    BBadge,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    
  },
  props: ["dados"],
  data() {
    return {
      fields: [
        { key: "num_os", label: this.$t('OS'), sortable: true },
        { key: "data_lancamento", label: this.$t('Data'), sortable: true },
        { key: "data_prazo", label: this.$t('Prazo_Entrega'), sortable: true },
        { key: "paciente", label: this.$t('Paciente'), sortable: true },
        { key: "servico_nome", label: this.$t('Servico'), sortable: true },
        { key: "situacao", label: this.$t('Status') },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
    };
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  filters: {
    // converteData(data) {
    //   if (!data) return "";
    //   return moment(data).format("DD/MM/YYYY");
    // },

    traduzSituacao(situacao) {
      return traducaoSituacao.traduzSituacao(situacao);
    },
  },
};
</script>

<style scoped>
</style>

