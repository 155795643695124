import i18n from '@/libs/i18n'

export default {

  meses: [
    {value: 1, nome: i18n.t('Janeiro')},
    {value: 2, nome: i18n.t('Fevereiro')},
    {value: 3, nome: i18n.t('Marco')},
    {value: 4, nome: i18n.t('Abril')},
    {value: 5, nome: i18n.t('Maio')},
    {value: 6, nome: i18n.t('Junho')},
    {value: 7, nome: i18n.t('Julho')},
    {value: 8, nome: i18n.t('Agosto')},
    {value: 9, nome: i18n.t('Setembro')},
    {value: 10, nome: i18n.t('Outubro')},
    {value: 11, nome: i18n.t('Novembro')},
    {value: 12, nome: i18n.t('Dezembro')}
  ],


  tipoDatas: [
    { value: 'data_lancamento', nome: i18n.t('Data_Lancamento')},
    { value: 'data_entregue', nome: i18n.t('Data_Entregue_Finalizado')},
    { value: 'data_prazo', nome: i18n.t('Data_Prazo')},
  ],

  diasSemana: {
    0: i18n.t('Dom'),
    1: i18n.t('Seg'),
    2: i18n.t('Ter'),
    3: i18n.t('Qua'),
    4: i18n.t('Qui'),
    5: i18n.t('Sex'),
    6: i18n.t('Sab')
  }

}
