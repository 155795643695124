export default {
    traduzSituacao: function (situacao) {
  
      const traducoes = {
        en: {
          Pedido: "Order",
          Produção: "Production",
          Pendente: "Pending",
          Cancelado: "Canceled",
          Finalizado: "Completed",
          "Saiu para Entrega": "Out for Delivery",
          Entregue: "Delivered",
          Produto: "Product",
          Vencido: "Overdue",
          "Em Rota": "In Route",
          Todos:"All",
          Entrada:"Entry",
          Saida:"Output",
          Saída:"Output",
          Despesa:"Expenses",
          Finalizada:"Completed",
          pendente:"pending",
          iniciada:"in progress",
          finalizada:"completed",
          "Etapas não Atribuidas " : "Unassigned Stages",
          "Etapas n\u00e3o Atribuidas" : "Unassigned Stages",
          "Pedido não Enviado" : "Order not sent",
          "Prova" : "Prove"
  
        },
        es: {
          Pedido: "Pedido",
          Produção: "Producción",
          Pendente: "Pendiente",
          Cancelado: "Cancelado",
          Finalizado: "Finalizado",
          "Saiu para Entrega": "En camino",
          Entregue: "Entregado",
          Produto: "Producto",
          Vencido: "Vencido",
          "Em Rota": "En Ruta",
          Todos:"Todos",
          Entrada:"Entrada",
          Saida:"Salida",
          Saída:"Salida",
          Despesa:"Gasto",
          Finalizada:"Completada",
          pendente:"pendiente",
          iniciada:"en progreso",
          finalizada:"completada",
          "Etapas não Atribuidas" : "Etapas no Asignadas",
          "Etapas n\u00e3o Atribuidas" : "Etapas no Asignadas",
          "Pedido não Enviado" : "Pedido no Enviado",
          "Prova" : "Prova"
        },
      };
  
      const idiomaAtual = localStorage.getItem("idioma");
      return traducoes[idiomaAtual]?.[situacao] || situacao;
    },
  }
  