<template>
  <b-card no-body class="card-developer-meetup" style="height: 314px" v-if="dashboard">
    <b-card-header class="pb-0">
      <b-card-title>{{ $t('Faturas_Atrasadas') }}</b-card-title>
      <feather-icon
        icon="BellIcon"
        size="21"
        class="text-danger"
        :badge="dashboardFiltered.length"
        badge-classes="badge-danger badge-glow"
      />
    </b-card-header>
    <hr class="mx-2 mb-0" />
    <b-card-body style="overflow-y: auto; max-height: 240px">
      <b-media v-for="(item, index) in dashboardFiltered" :key="index" no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-danger" size="34">
            {{ item.num }}
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ item.vencimento | converteData }}
            <small v-if="item.parcela != '1 / 1'"> - {{item.parcela}} </small>
          </h6>
          
          <small> {{ $t('R$') }} ' ' {{ item.total | formataMoeda }},</small>
          <small> {{ item.forma | Uppercase }}</small>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";
import { mapState } from "vuex";
import moment from 'moment'
import formataMoeda from "@/mixins/formataMoeda";
import formataData from "@/mixins/formataData";

/* eslint-disable global-require */
export default {
  mixins:[formataMoeda, formataData],
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard.dashboard.faturas,
    }),
    dashboardFiltered() {
      return this.dashboard.filter((v) => {
        return Date.parse(v.vencimento) < Date.parse(this.$getDate.hoje())
      })
    },
  },

  filters: {
    // converteData(data) {
    //   if (!data) return;

    //   const d = data.split("-");
    //   return `${d[2]}/${d[1]}/${d[0]}`;
    // },
    Uppercase(data) {
      if (!data) return;

      const d = data.charAt(0).toUpperCase() + data.slice(1);  
        return d;
    }
  },
};
</script>
<style scoped>
</style>
