var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"adicionarUsuario","hide-footer":"","centered":"","size":"md","title":_vm.$t('Adicionar_Usuario_Externo')},on:{"shown":_vm.abriuModalAdicionarUsuario}},[_c('b-card',[(_vm.getErrors.hasMessage)?_c('b-alert',{attrs:{"variant":_vm.getErrors.color,"show":""}},[_vm._l((_vm.getErrors.messageArray),function(error,index){return _c('div',{key:index,staticClass:"alert-body my-2"},[_vm._v(" "+_vm._s(error[0])+" ")])}),_c('div',{staticClass:"alert-body"},[_vm._v(_vm._s(_vm.getErrors.message))])],2):_vm._e(),_c('validation-observer',{ref:"novoUsuarioValidation"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Selecione_um_Dentista_Conveniado'),"label-for":"account-tipo"}},[_c('validation-provider',{attrs:{"name":"Dentista Conveniado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.conveniadosSemCadastroUsuario,"state":errors.length > 0 ? false:null,"text-field":"conveniado_nome","value-field":"id"},on:{"change":_vm.selecionouConveniado},model:{value:(_vm.form.conveniado_id),callback:function ($$v) {_vm.$set(_vm.form, "conveniado_id", $$v)},expression:"form.conveniado_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nome'),"label-for":"account-username"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"nome","placeholder":_vm.$t('Nome_do_Usuario')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"account-e-mail"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nova_Senha'),"label-for":"senha"}},[_c('validation-provider',{attrs:{"name":"Senha","rules":"required|password|min:8|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"senha","name":"senha","placeholder":_vm.$t('Nova_Senha')},model:{value:(_vm.form.senha),callback:function ($$v) {_vm.$set(_vm.form, "senha", $$v)},expression:"form.senha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Confirmar_Senha'),"label-for":"confirmar-senha"}},[_c('validation-provider',{attrs:{"name":"Confirmar Senha","rules":"required|confirmed:Senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmar-senha","name":"confirmarSenha","placeholder":_vm.$t('Confirmar_Senha')},model:{value:(_vm.confirmarSenha),callback:function ($$v) {_vm.confirmarSenha=$$v},expression:"confirmarSenha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('Obs'))+":")]),_vm._v(" "+_vm._s(_vm.$t('Dentistas_Conveniados_so_terao_acesso_a_tela_de_Inicio_e_Pedidos_sera_mostrado_apenas_servicos_atribuidos_ao_dentista_conveniado'))+" ")])])])],1),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"auto"}},[(_vm.form.conveniado_id > 0)?_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.submit},on:{"click":function($event){$event.preventDefault();return _vm.cadastrarUsuario.apply(null, arguments)}}},[(_vm.spinner)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.spinner)?_c('span',[_vm._v(_vm._s(_vm.$t('Aguarde')))]):_c('span',[_vm._v(_vm._s(_vm.$t('Cadastrar_Usuario')))])],1):_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('adicionarUsuario')}}},[_vm._v(_vm._s(_vm.$t('Voltar')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }