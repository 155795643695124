export default {

    situacaoOpcoes: [
        { value: "Produção", text: "$t('Producao')" },
        { value: "Pendente", text: "$t('Pendente')" },
        { value: "Prova", text: "$t('Prova')" },
        { value: "Cancelado", text: "$t('Cancelado')" },
        { value: "Finalizado", text: "$t('Finalizado')" },
        { value: "Saiu para Entrega", text: "$t('Saiu_para_Entrega')" },
        { value: "Pedido não Enviado", text: "$t('Pedido_nao_Enviado')" },
        { value: "Pedido", text: "$t('Pedido')" },
        { value: "Entregue", text: "$t('Entregue')" },
    ],

    situacaoOpcoesFiltros: [
        { value: "Produção", text: "$t('Producao')" },
        { value: "Pendente", text: "$t('Pendente')" },
        { value: "Prova", text: "$t('Prova')" },
        { value: "Cancelado", text: "$t('Cancelado')" },
        { value: "Finalizado", text: "$t('Finalizado')" },
        { value: "Saiu para Entrega", text: "$t('Saiu_para_Entrega')" },
        { value: "Pedido não Enviado", text: "$t('Pedido_nao_Enviado')" },
        { value: "Pedido", text: "$t('Pedido')" },
        { value: "Entregue", text: "$t('Entregue')" },
    ],

    situacaoOpcoesLancarReceitas: [
        { value: "Produção", text: "$t('Producao')" },
        { value: "Pendente", text: "$t('Pendente')" },
        { value: "Prova", text: "$t('Prova')" },
        { value: "Cancelado", text: "$t('Cancelado')" },
        { value: "Finalizado", text: "$t('Finalizado')" },
        { value: "Saiu para Entrega", text: "$t('Saiu_para_Entrega')" },
        { value: "Entregue", text: "$t('Entregue')" },
        { value: "Produto", text: "$t('Produto')" },
        { value: "Transporte", text: "$t('Transporte')" },
    ],

    situacaoOpcoesFiltrosAgenda: [
        { value: "Produção", text: "$t('Producao')" },
        { value: "Pendente", text: "$t('Pendente')" },
    ],

    situacaoCor: [
        {
            'Produção': 'primary',
            'Pendente': 'light-danger',
            'Prova': 'light-warning',
            'Cancelado': 'danger',
            'Finalizado': 'light-primary',
            'Saiu para Entrega': 'light-info',
            'Pedido não Enviado': 'danger',
            'Pedido': 'light-info',
            'Entregue': 'success',
            'Produto': 'secondary',
            'Transporte': 'light-secondary',
        }
    ]
}
