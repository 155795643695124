import Guard from './../services/auth'
import i18n from './../libs/i18n'

export default [
  {
    path: '/financeiro',
    name: 'financeiro',
    component: () => import('@/views/financeiro/FinanceiroPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: i18n.t('Financeiro'),
      owner: true
    },
  },
]