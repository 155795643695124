export default {
    traduzOpcoesDatas: function (opcoes) {
  
      const traducoes = {
        en: {
          "Data Lançamento": "Launch Date",
          "Data Entregue/Finalizado": "Delivered Date",
          "Data Prazo": "Deadline",
          Janeiro: "January",
          Fevereiro: "February",
          Março: "March",
          Abril: "April",
          Maio: "May",
          Junho: "June",
          Julho: "July",
          Agosto: "August",
          Setembro: "September",    
          Outubro: "October",
          Novembro: "November",
          Dezembro: "December",
  
        },
        es: {
          "Data Lançamento": "Data de lanzamiento",
          "Data Entregue/Finalizado": "Data de entrega/finalizado",
          "Data Prazo": "Data de prazo",
          Janeiro : "Enero",
          Fevereiro : "Febrero",
          Março : "Marzo",
          Abril : "Abril",
          Maio : "Mayo",
          Junho : "Junio",
          Julho : "Julio",
          Agosto : "Agosto",
          Setembro : "Setiembre",
          Outubro : "Octubre",
          Novembro : "Noviembre",
          Dezembro : "Diciembre",
        },
      };
  
      const idiomaAtual = localStorage.getItem("idioma");
      return traducoes[idiomaAtual]?.[opcoes] || opcoes;
    },
  }
  