<template>
  <b-card class="card-transaction" no-body v-if="dashboard">
    
    <b-card-header>
      <b-card-title>{{ $t('Servicos_Finalizados') }}</b-card-title>
      <span class="text-muted">{{ $t('Essa_Semana') }}</span>
    </b-card-header>
    <hr class="my-0 mx-2" />

    <b-card-body>
      <b-row class="my-2">
        <b-col md="2" class="pr-0">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="btn-icon"
            @click="abrirModal(dashboard.entregue)"
          >
            <feather-icon size="18" icon="CheckIcon" />
          </b-button>
        </b-col>
        <b-col align-self="center" md="" class="pl-1">
          <h4 style="padding-top: 9px">{{ $t('Entregues') }}</h4>
        </b-col>
        <b-col align-self="center" md="2">
          <h2 style="padding-top: 4px" class="float-right font-weight-bolder">
            {{dashboard.totalEntregue}}
          </h2>
        </b-col>
      </b-row>
    </b-card-body>
    <ModalEntregues :dados="dados"/>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from 'vue-ripple-directive'
import ModalEntregues from "./ModalEntregues.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BRow,
    BCol,
    BButton,
    BModal,
    VBModal,

    ModalEntregues,
  },
   directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      dados: [],
    };
  },
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard.dashboard.servicos
    })
  },
  methods: {
    abrirModal(conteudo) {
      this.dados = conteudo
      this.$bvModal.show('modalEntregues')
    }
  }
};
</script>
