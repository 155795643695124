import Guard from './../services/auth'
import i18n from './../libs/i18n'

export default [
  {
    path: '/pedidos',
    name: 'pedidos',
    component: () => import('@/views/pedidos/PedidosPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: i18n.t('Pedidos'),
      owner: false,
      breadcrumb: [
        {
          text: i18n.t('Lista'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/pedidos/novo/:id?',
    name: 'pedidos_novo',
    component: () => import('@/views/pedidos/LancarPedido.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    owner: false,
    meta: {
      pageTitle: i18n.t('Pedidos'),
      breadcrumb: [
        {
          text: i18n.t('Novo'),
          active: true,
        },
      ],
    },
  },
]
