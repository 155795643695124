<template>
  <div>
    <b-row>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal
          icon="FlagIcon"
          color="warning"
          :statistic="totalReceber"
          :statistic-title="$t('Faturas_a_Pagar')"
        />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="danger"
          :statistic="totalAtrasados"
          :statistic-title="$t('Faturas_em_Atraso')"
        />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal
          icon="InfoIcon"
          color="success"
          :statistic="totalPagamentos"
          :statistic-title="$t('Pagamentos')"
        />
      </b-col>
    </b-row>

    <TabelaFinanceiro />
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { mapState, mapGetters } from "vuex";
import TabelaFinanceiro from "./TabelaFinanceiro.vue";
import formataMoeda from "@/mixins/formataMoeda"

export default {

  mixins: [formataMoeda],

  name: "FinanceiroPrincipal",
  components: {
    BRow,
    BCol,

    StatisticCardHorizontal,
    TabelaFinanceiro,
  },
  computed: {
    ...mapState({
      laboratorios: (state) => state.laboratorios.laboSelecionado,
      faturas: (state) => state.financeiro.faturas,
      dataInicio: (state) => state.financeiro.dataInicio,
      dataFinal: (state) => state.financeiro.dataFinal,
      totalReceber: (state) => state.financeiro.total_receber,
      totalAtrasados: (state) => state.financeiro.total_atrasados,
      totalPagamentos: (state) => state.financeiro.totalPagamentos,
      filtroPagamentos: (state) => state.financeiro.filtroPagamentos,
    }),

    ...mapGetters({
      selecionado: "laboratorios/selecionado",
    }),

  },
   mounted(){
     if(!this.faturas.length) {
       this.buscaDados();
     }
  },
  watch: {
    laboratorios(newValue, oldvalue) {
     this.buscaDados();
    },
  },
  methods: {
    // formatMoeda(valor) {
    //   if (!valor) return "0,00";

    //   return valor.toLocaleString("pt-BR", {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   });
    // },
     buscaDados() {
      if (this.selecionado) {
        this.$store.dispatch("financeiro/listaFaturas", {
          laboratorioSelecionado: this.selecionado,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
