<template>
  <div id="printMe">
    <h4 class="text-center mb-5">
      {{ $t('Relatorio_Pedidos') }} <span v-if="mes"> {{ mesExtenso }} </span> {{ $t('de') }}
      {{ ano }}
    </h4>
    {{ sort }} {{ counter }}

    <table>
      <thead>
        <th width="80">{{ $t('OS') }}</th>
        <th width="180">
          {{ tipoPeriodo }}
        </th>
        <th width="180">{{ $t('QTD') }}</th>
        <th width="360">{{ $t('SERVICO') }}</th>
        <th width="250">{{ $t('DENTISTA') }}</th>
        <th width="250">{{ $t('PACIENTE') }}</th>
        <th width="250">{{ $t('SITUACAO') }}</th>
      </thead>
      <tbody v-for="(pedido, index) in pedidoFiltrado" :key="index">
        <td>{{ pedido.num_os }}</td>
        <td v-if="tipoPeriodo == 'ENTREGUE'">
          {{ pedido.data_entregue | converteDataHora }}
        </td>
        <td v-else-if="tipoPeriodo == 'PRAZO'">{{ pedido.data_prazo_lab }}</td>
        <td v-else>{{ pedido.data_lancamento | converteData }}</td>
        <td>{{ pedido.quantidade }}</td>
        <td>{{ pedido.servico_nome }}</td>
        <td>{{ pedido.conveniado_nome }}</td>
        <td>{{ pedido.paciente }}</td>
        <td>{{ pedido.situacao }}</td>
      </tbody>
    </table>
  </div>
</template>

<script>
import filtroMeses from "@/services/filtroMeses";
import moment from "moment";
import formataData from "@/mixins/formataData";
export default {

  mixins: [formataData],
  name: "ImprimirRelatorio",
  props: ["pedidos", "mes", "ano", "tipo", "sort", "counter"],

  data() {
    return {
      ...filtroMeses,
      /* meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],*/
    };
  },

  computed: {
    mesExtenso() {
      if(this.mes) {
        return this.meses[this.mes - 1]?.nome
      }
    },
    pedidoFiltrado() {
      var key = this.sort;
      var count = this.counter;

     return this.pedidos.sort(function (a, b) {
      if (count %2 == 0) {
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      } else {
        return a[key] > b[key] ? -1 : a[key] < b[key] ? -1 : 0;
      }

      });
    },

    tipoPeriodo() {
      let nome = this.$t('ENTRADA');
      if (this.tipo === "data_entregue") {
        nome = this.$t('ENTREGUE');
      } else if (this.tipo === "data_prazo_lab") {
        nome = this.$t('PRAZO');
      }
      return nome;
    },
  },

  filters: {
  //   converteData(data) {
  //     if (!data) return;

  //     const d = data.split("-");
  //     return `${d[2]}/${d[1]}/${d[0]}`;
  //   },
  //   converteDataHora(data) {
  //     if (!data) return;

  //     return moment(data).format("DD/MM/YYYY H:mm");
  //   },
  },
};
</script>

<style scoped>
</style>
