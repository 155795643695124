<template>
  <b-card>
    <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
      <div class="alert-body" v-for="(error, index) in getErrors.messageArray" :key="index">
        {{error[0]}}
      </div>

      <div class="alert-body">{{getErrors.message}}</div>
    </b-alert>
    <validation-observer ref="simpleRules">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="$t('Senha_Antiga')"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha Antiga"
                rules="required"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('Digite_a_Senha_Antiga')"
                    :class="errors.length > 0 ? 'is-invalid':null"

                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('Nova_Senha')"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha"
                vid="Password"
                rules="required|password|min:8"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('Nova_Senha')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('Repetir_Nova_Senha')"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmar Senha"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge"  :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('Nova_Senha')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="gravar"
              :disabled="submit"
            >
              <b-spinner small v-if="spinner" />
              <span v-if="spinner">{{ $t('Aguarde') }}</span>
              <span v-else>{{ $t('Gravar') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('Cancelar') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>

  </b-card>
</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSpinner, BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSpinner,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errors: [],
      spinner: false,
      submit: false,
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      },
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    gravar() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.spinner = true
          this.proDisabled = true

          const payload = {
            'password': this.newPasswordValue,
            'password_old': this.passwordValueOld,
          }

          this.$http.post('odontodocs/change-password', payload).then( res => {

            this.spinner = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Senha_Alterada_com_Sucesso'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })

            // resetar campos
            this.resetarCampos()
            this.$refs.simpleRules.reset()
          })
            .catch((error) => {
            let res = this.$responseMessages.getMessage(error, null);
            this.getErrors = res;
          })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });

        }
      })
    },

    resetarCampos() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
      this.getErrors.hasMessage = false
    }
  },
}
</script>

<style></style>
