<template>
  <div>
    <b-row class="mb-1">
      <b-col md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          :pressed="!filtroPagamentos"
          variant="outline-primary"
          @click="faturaClicado()"
        >
          <feather-icon icon="FileIcon" class="mr-50" />
          <span class="align-middle">{{ $t('Faturas') }}</span>
        </b-button>

        <b-button
          class="ml-1"
          :pressed="filtroPagamentos"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="outline-primary"
          @click="pagamentoClicado()"
        >
          <feather-icon icon="DollarSignIcon" class="mr-50" />
          <span class="align-middle">{{ $t('Pagamentos') }}</span>
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row class="mb-1">
        <b-col md="2" class="">
          <v-select
            id="filtro"
            v-model="filtro"
            :options="filtros"
            :reduce="(filtro) => filtro.value"
            label="text"
            class="select-size-sm"
          >
          </v-select>
        </b-col>

        <b-col md="6">
          <b-input-group size="sm">
            <b-form-datepicker
              id="dataInicio"
              size="sm"
              v-model="dataInicio"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              hide-header
              :label-close-button="$t('Fechar')"
              :label-reset-button="$t('Apagar_Data')"
              labelHelp=""
              :locale=" $t('pt_BR')"
              :placeholder="$t('Data')"
              reset-button
              @input="atualizaDatas"
            />
            <b-form-datepicker
              id="dataFinal"
              size="sm"
              v-model="dataFinal"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              hide-header
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              :label-close-button="$t('Fechar')"
              :label-reset-button="$t('Apagar_Data')"
              labelHelp=""
              :locale=" $t('pt_BR')"
              :placeholder="$t('Data')"
              reset-button
              @input="atualizaDatas"
            />
          </b-input-group>
        </b-col>
        <b-col md="4">
          <b-button
            class="float-right"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="gradient-primary"
            @click="extrato"
          >
            <feather-icon icon="PrinterIcon" class="mr-50" />
            <span class="align-middle">{{ $t('Extrato') }}</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        v-if="!filtroPagamentos"
        striped
        hover
        small
        responsive
        :items="faturasFiltradas"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #cell(vencimento)="data">
          {{ data.item.vencimento | converteData }}
        </template>

        <template #cell(forma)="data">
          <b-badge :variant="formasEtiquetas[data.item.forma]">
            {{ formasAgrupadas[data.item.forma] }}
          </b-badge>
        </template>

        <template #cell(total)="data">
          {{ data.item.total | formataMoeda }}
        </template>
        <template #cell(pago)="data">
          {{ data.item.pago | formataMoeda }}
        </template>
        <template #cell(saldo)="data">
          {{ data.item.saldo | formataMoeda }}
        </template>
        <template #cell(etiquetaNome)="data">
          <b-badge :variant="data.item.etiquetaCor"> {{ data.item.etiquetaNome }} </b-badge>
        </template>

        <template #cell(opcoes)="data">
          <!--
          <b-button
            class="btn-icon"
            variant="flat-secondary"
            @click="data.toggleDetails"
            style="padding: 2px"
          >
            <feather-icon
              :class="data.detailsShowing ? 'text-primary' : 'text-default'"
              @click="data.detailsShowing ? null : getItems(data.item)"
              icon="EyeIcon"
              size="16"
            />
          </b-button>
-->
          <b-button
            v-if="data.item.token"
            class="btn-icon"
            variant="flat-secondary"
            @click="imprimir(data.item)"
            style="padding: 2px"
          >
            <feather-icon icon="PrinterIcon" size="16" />
          </b-button>

          <template v-if="Number(data.item.numero) > 0">
<!--
            <b-button
                v-if="data.item.atualizarBoletoSmart"
                class="btn-icon"
                variant="flat-primary"
                style="padding: 2px"
                @click="atualizarBoleto(data)"
            >
              <b-badge variant="primary">
                <b-spinner v-if="spinner == data.index" small />
                Atualizar Boleto
              </b-badge>
            </b-button>
-->

            <b-button
                class="btn-icon"
                variant="flat-success"
                style="padding: 2px"
                @click="imprimirBoleto(data.item)"
            >
              <b-badge variant="success">
                {{ $t('Imprimir_Boleto') }}
              </b-badge>
            </b-button>
          </template>

        </template>
      </b-table>
      <b-table
        v-if="filtroPagamentos"
        striped
        hover
        small
        responsive
        :items="pagamentos"
        :fields="fieldsPagamento"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #cell(data_recebimento)="data">
          {{ data.item.data_recebimento | converteData }}
        </template>

        <template #cell(forma)="data">
          <b-badge :variant="formasEtiquetas[data.item.forma]">
            {{ formasAgrupadas[data.item.forma] }}
          </b-badge>
        </template>

        <template #cell(valor)="data">
          {{ data.item.valor | formataMoeda }}
        </template>
        <template #cell(juros)="data">
          {{ data.item.juros | formataMoeda }}
        </template>
        <template #cell(total)="data">
          {{ data.item.total | formataMoeda }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BPopover,
  BRow,
  BSpinner,
  BTable,
  VBPopover,
} from "bootstrap-vue";
import {mapGetters, mapState} from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from "moment";
import formaPagamento from "../../services/formaPagamento.js";
import formataMoeda from "@/mixins/formataMoeda";
import formataData from "@/mixins/formataData";

export default { 

  mixins: [formataMoeda, formataData],
  
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormDatepicker,
    BFormGroup,
    BInputGroup,
    BTable,
    vSelect,
    VBPopover,
    BSpinner,
    BPopover,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: -1,
      dataInicio: moment().startOf("month").format("YYYY-MM-DD"),
      dataFinal: moment().endOf("month").format("YYYY-MM-DD"),
      filtro: 1,
      filtros: [
        { value: 1, text: this.$t('A_Pagar') },
        { value: 2, text: this.$t('Pagos') },
        { value: 0, text: this.$t('Todos') },
      ],
      ...formaPagamento,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fieldFaturaItems: [
        { key: "num_os", label: this.$t('Os') },
        { key: "servico_nome", label: this.$t('Servico') },
        { key: "quantidade", label: this.$t('Quantidade') },
        { key: "valor", label: this.$t('Valor') },
        { key: "valor_total", label: this.$t('Subtotal') },
        { key: "situacao", label: this.$t('Situacao') },
        { key: "data_entregue", label: this.$t('Data_Entregue') },
      ],
      fields: [
        {
          key: "vencimento",
          label: this.$t('Vencimento'),
          sortable: true
        },
        // { key: "referencia", label: "referencia", sortable: true },
        { key: "num", label: this.$t('Num_Fatura'), sortable: true },
        { key: "parcela", label: this.$t('Parcela'), thStyle: { width: "25px" } },
        {
          key: "forma",
          label: this.$t('Forma_Pagamento'),
          sortable: true
        },
        {
          key: "total",
          label: this.$t('Valor'),
          sortable: true
        },
        { key: "pago", label: this.$t('Pago') },
        { key: "saldo", label: this.$t('Saldo')},
        { key: "etiquetaNome", label: this.$t('Situacao')},
        { key: "opcoes", label: this.$t('Opcoes')},
      ],
      fieldsPagamento: [
        { key: "data_recebimento", label: this.$t('Data'), sortable: true },
        { key: "forma", label: this.$t('Forma_Pagamento') },
        { key: "valor", label: this.$t('Valor') },
        { key: "opcoes", label: this.$t('Opcoes') },
      ],
    };
  },
/*  watch: {
    laboratorios(newValue, oldvalue) {
      this.buscaDados();
    },
  },*/
  computed: {
    ...mapState({
      laboratorios: (state) => state.laboratorios.laboSelecionado,
      faturas: (state) => state.financeiro.faturas,
      pagamentos: (state) => state.financeiro.pagamentos,
      items: (state) => state.financeiro.items,
      filtroPagamentos: (state) => state.financeiro.filtroPagamentos,
      cliente_id: state => state.financeiro.cliente_id,
      cliente_token: state => state.financeiro.cliente_token,
    }),
    ...mapGetters({
      selecionado: "laboratorios/selecionado",
    }),

    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    faturasFiltradas() {
      if(!this.faturas.length) return []

      let resultado = []

      this.faturas.forEach( (value) => {

        let etiquetaNome = this.$t('a_pagar')
        let etiquetaCor = 'light-primary'
        let atualizarBoletoSmart = false

        let dataHoje = Date.parse(moment(this.$getDate.hoje()).format('YYYY-MM-DD'))

        if(Number(value.saldo) > 0) {

          if(Date.parse(moment(value.vencimento).format('YYYY-MM-DD')) < dataHoje) {
            etiquetaNome = this.$t('vencida') 
            etiquetaCor = 'light-danger'
          }

          if(Date.parse(moment(value.data_limite).format('YYYY-MM-DD')) < dataHoje ) {
            atualizarBoletoSmart = true
          }

        } else{

          etiquetaNome = this.$t('pago')
          etiquetaCor = 'light-success'

        }

        resultado.push( {...value, etiquetaNome, etiquetaCor, atualizarBoletoSmart })

      })

      if(this.filtro > 0) {

        if(this.filtro === 1) {
          return resultado.filter( v => v.saldo > 0)
        }

        if(this.filtro === 2) {
          return resultado.filter( v => v.saldo == 0)
        }

      }

      return resultado
    }
  },
  methods: {
    imprimirBoleto(boleto) {

      let url = `${this.$http.defaults.baseWebURL}cobranca/boletos/${boleto.id_company}/${boleto.numero}`

      if(boleto.gateway == 'asaas') {
        url = `https://asaas.com/i/${boleto.id_externo.substring(4)}`
      }
      window.open(url,"_blank")
    },
    imprimir(fatura) {
      let modelo = 1;
      window.open(
        this.$http.defaults.baseWebURL +
          "/faturas/imprimir/" +
          fatura.num +
          "/" +
          modelo +
          "/" +
          fatura.token
        //  +"_blank"
      );
    },
    extrato() {
      let link = `${this.$http.defaults.baseWebURL}/faturas/extrato/${this.cliente_id}/10/${this.cliente_token}?data_ini=${this.dataInicio}&data_fin=${this.dataFinal}`

      window.open(link, '_blank')
    },
    buscaDados() {
      this.$store.dispatch("financeiro/listaFaturas", {
        laboratorioSelecionado: this.selecionado,
      });
    },

    atualizarBoleto(fatura) {
      this.spinner = fatura.index;

      fatura.item.data_vencimento = fatura.item.vencimento

      let payload = {
        boleto: fatura.item,
      };

      this.$http
        .post("odontodocs/financeiro/boleto/recriar/" + this.selecionado, payload)
        .then((res) => {

          this.imprimirBoleto({
            id_company: res.data.id_company,
            numero: res.data.numero
          });

          this.buscaDados();

        })
        .catch((error) => {
          this.getErrors = this.$responseMessages.getMessage(error, null);
        })
        .finally(() => {
          this.spinner = -1;
        });
    },

    atualizaDatas() {
      // if (this.dataInicio.length > 0 && this.dataFinal.length > 0) {
      const payload = {
        dataInicio: this.dataInicio,
        dataFinal: this.dataFinal,
      };
      this.$store.commit("financeiro/SET_DATAS", payload);

      this.buscaDados();

      // }
    },

    alterouFiltro(/* tipo */) {
      // this.filtro = tipo;

      if (!this.filtro) this.filtro = 0;
      this.$store.commit(
        "financeiro/STORE_PAGAR",
        this.filtro == 0 || this.filtro == 1
      );
      this.$store.commit(
        "financeiro/STORE_PAGAS",
        this.filtro == 0 || this.filtro == 2
      );

      this.buscaDados();
    },

    /*getItems(fatura) {
      if (fatura.id) this.$store.dispatch("financeiro/listaItems", fatura.id);

      return fatura.id;
      } */
    faturaClicado() {
      this.$store.commit("financeiro/STORE_PAGAMENTOS", false);
    },
    pagamentoClicado() {
      this.$store.commit("financeiro/STORE_PAGAMENTOS", true);
    },
  },

  filters: {
 /*   formataMoeda(valor) {
      if (!valor) return "0,00";

      return Number(valor).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },*/

    // formataData(data) {
    //   if (!data) return;

    //   return moment(data).format("DD/MM/YYYY");
    // },
  },
};
</script>

<style>
</style>
