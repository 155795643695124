<template>
  <b-modal
      id="verAnotacoes"
      centered
      size="xl"
      :title="$t('Anotacoes')"
      hide-header-close
      hide-footer
  >
    <b-row>
      <b-col cols="12">
        <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
          <div class="alert-body my-2" v-for="(error, index) in getErrors.messageArray" :key="index">
            {{ error[0] }}
          </div>
          <div class="alert-body">{{ getErrors.message }}</div>
        </b-alert>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-table
              small
              hover
              striped
              responsive="lg"
              class="my-2"
              :fields="fields"
              :items="historico"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
          >
            <template #cell(created_at)="data">
              {{ data.item.created_at | converteDataHora }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="mt-2">
      <b-col>
        <b-button
            variant="gradient-primary"
            block
            @click="$bvModal.hide('verAnotacoes')"
        >
          {{ $t('Fechar') }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import {
  BAlert,
  BModal,
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
} from 'bootstrap-vue'
import moment from "moment/moment";
export default {
  props: ['historico'],
  name: "ModalVerAnotacoes",
  components: {
    BAlert,
    BModal,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      fields: [
        { key: 'created_at', label: 'Data', sortable: true},
        { key: 'anotacao', label: 'Anotação', sortable: true }
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
    },
  },
  filters: {
    converteDataHora(data){
      if(!data) return
      return moment(data).format('DD/MM/YYYY H:mm')
    },
  }
}

</script>

<style scoped>

</style>
