<template>
  <b-card text-variant="center" class="card card-congratulations">
    <!-- images -->
    <b-img class="congratulations-img-left" />
    <b-img class="congratulations-img-right" />
    <!--/ images -->

    <b-avatar variant="primary" size="78" class="shadow">
      <feather-icon size="28" icon="AwardIcon" />
    </b-avatar>
    <h1 class="mb-1 text-white">{{ $t('Bem_vindo_ao') }} OdontoDocs</h1>
    <b-card-text class="m-auto w-75">
      {{ $t('Controle_total_dos_Pedidos_ao_Laboratorio_e_Compartilhamento_de_arquivos') }}! <strong></strong>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
};
</script>
