import moment from "moment/moment";
const formatoData = localStorage.getItem("idioma") === "en" ?  'MM/DD/YYYY' : 'DD/MM/YYYY'
const formatoDataHora = localStorage.getItem("idioma") === "en" ?  'MM/DD/YYYY H:mm' : 'DD/MM/YYYY HH:mm'
export default {

  data() {
    return {
      formatoData: formatoData,
      formatoDataHora: formatoDataHora
    }
  },

  filters: {

    converteHora(hora) {

      if(!hora) return ''
      return hora.slice(0, -3)

    },

    converteDataHora(dataHora) {

      if (!dataHora) return;
      return moment(dataHora).format(formatoDataHora);

    },

    converteData(data) {

      if (!data) return;
      return moment(data).format(formatoData);

    },

  }

}
