import i18n from "@/libs/i18n";

const locale = i18n.locale

let decimal = ","
let thousands = "."

if(locale === "en"){
   decimal = "."
   thousands = ","
}

export default {

  data() {
    return {
      money: {
        decimal: decimal,
        thousands: thousands,
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      }
    }
  },

  filters: {
    formataMoeda(valor) {
      
      if (!valor) return "0,00";

      if (Array.isArray(valor) && valor.length === 0) return '0,00';
      
      let vl = parseFloat(valor);
      return vl.toLocaleString(i18n.t('pt_BR'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  }

}
