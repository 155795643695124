<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Uploads</b-card-title>
      <span class="text-muted">. . .</span>
    </b-card-header>
    <hr class="my-0 mx-2" />

    <b-card-body>
      <b-row class="my-2">
        <b-col align-self="center" md="2" class="pr-0">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
          >
            <feather-icon size="18" icon="UploadIcon" />
          </b-button>
        </b-col>
        <b-col align-self="center" md="6" class="pl-1">
          <h4 style="padding-top: 9px">{{ $t('Total_Utilizado') }}</h4>
        </b-col>
        <b-col align-self="center" md="4">
          <h2 style="padding-top: 4px;" class="float-right font-weight-bolder">
            1.58GB
          </h2>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
};
</script>
