<template>

  <b-row class="justify-content-md-center">

    <b-card :title="$t('Foto_do_Perfil')" img-alt="Image" img-top  style="margin: 20px auto;text-align: center">
      <b-card-text>

        <vue-avatar
            :width=210
            :height=210
            ref="vueavatar"
            @vue-avatar-editor:image-ready="onImageReady"
            :border=1
            image=""
            @errorImage="errorImagem = $event, addImagem = !$event"
            v-if="!hasImagem"

        >
        </vue-avatar>
        <br>
        <vue-avatar-scale
            ref="vueavatarscale"
            @vue-avatar-editor-scale:change-scale="onChangeScale"
            :width=210
            :min=0
            :max=30
            :step=0.02
            v-if="!hasImagem"
        >

        </vue-avatar-scale>

        <div style="width: 210px; height: 210px;border: 1px solid #807e7e;margin-left: auto; margin-right: auto;" v-if="hasImagem" >
          <b-img :src="url ? `https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${url}` : '' " width="210"  fluid center ></b-img>
        </div>

        <b-alert variant="danger" v-if="errorImagem" show>
          <div class="alert-body">
            {{ $t('Arquivo_de_imagem_invalido_permitidos_JPG_JPEG_e_PNG') }}
          </div>
        </b-alert>


      </b-card-text>

      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div class="alert-body" v-for="(error, index) in getErrors.messageArray" :key="index">
          {{error[0]}}
        </div>

        <div class="alert-body">{{getErrors.message}}</div>
      </b-alert>


      <template #footer>

        <b-button  class="mb-75 mr-75"  variant="gradient-success" @click="gravaImagem" v-if="!hasImagem" :disabled="errorImagem">
          <feather-icon class="mr-50" icon="ImageIcon" /> {{ $t('Gravar_Imagem') }}
        </b-button>

        <b-button  class="mb-75 mr-75"  variant="gradient-success" @click="hasImagem = false" v-else>
          <feather-icon class="mr-50" icon="ImageIcon" /> {{ $t('Trocar_Imagem') }}
        </b-button>

        <b-button class="mb-75 mr-75" variant="gradient-primary" @click="limparImagem()" >
          <feather-icon class="mr-50" icon="XIcon" />
          {{ $t('Limpar') }}
        </b-button>
      </template>
    </b-card>
  </b-row>

</template>

<script>

import {BCard, BCardText, BRow, BButton,BImg, BAlert} from 'bootstrap-vue'
import VueAvatar from '../crop/src/components/VueAvatar.vue'
import VueAvatarScale from '../crop/src/components/VueAvatarScale.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  data() {
    return {
      errorImagem: false,
      addImagem: false,
      url: this.$store.state.user.user.url,
      hasImagem: this.$store.state.user.user.url ? true : false,
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      }
    }
  },

  components: {
    BCard, BCardText, BRow, BAlert, BButton, BImg,  VueAvatar, VueAvatarScale
  },


  methods: {

    gravaImagem(){

      if(!this.errorImagem){

        this.url = ''

        if(this.addImagem){
          var img = this.$refs.vueavatar.getImageScaled()

          if(img){
            this.url = img.toDataURL()
          }

        }

        this.$http.put('odontodocs/usuarios/imagem/'+this.$store.state.user.user.id, {url: this.url}).then(res => {
          this.url = res.data
          this.hasImagem = true

          this.$responseMessages.getMessage(false, this.$t('Foto_do_Perfil_alterada_com_Sucesso'))

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Foto_do_Perfil_alterada_com_Sucesso'),
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        }).catch(error => {
          let res = this.$responseMessages.getMessage(error, null)
          this.getErrors = res
        })
      }
    },

    onChangeScale (scale) {
      this.$refs.vueavatar.changeScale(scale)
    },
    onImageReady(scale){
      this.$refs.vueavatarscale.setScale(scale)
    },
    limparImagem(){

      if(this.addImagem){
        this.$refs.vueavatar.state.image.resource = ''
      }

      this.addImagem = false
      this.hasImagem = false
      this.url = ''
      this.errorImagem = false
    },
  }
}

</script>
