import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const localeFiles = require.context('@/libs/i18n/locales', false, /\.json$/);
  const messages = {};
  localeFiles.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = localeFiles(key);
    }
  });
  return messages;
}

const codigosIdiomas = {
  'pt': { idioma: 'pt-br'},
  'pt-BR': { idioma: 'pt-br'},
  'pt-br': { idioma: 'pt-br'},
  'en-US': { idioma: 'en' },
  'en': { idioma: 'en'},
  'es': { idioma: 'es' }
};

let l = localStorage.getItem('idioma') ??  navigator.language;

if(codigosIdiomas[l] === undefined || codigosIdiomas[l] === null) {
  l = 'en'
}

const idiomaCodificado = codigosIdiomas[l];
localStorage.setItem('idioma', idiomaCodificado.idioma);

export default new VueI18n({
  locale: idiomaCodificado.idioma,
  fallbackLocale: 'pt-br',
  messages: loadLocaleMessages(), 
});
