<template>
  <b-modal id="modalTermos" :title="$t('Termos_de_Servico')" 
  hide-footer 
  modal-class="modal-terms">

    <div class="termos-content" 
    style="max-height: 500px; 
    overflow-y: auto;">

      <h4>{{ $t('Termos_de_Servico') }} - {{ nomeLaboratorio }}</h4>
      
      <div v-if="cliente && cliente.configuracoes && cliente.configuracoes.termos_servico"
           v-html="cliente.configuracoes.termos_servico">
      </div>
      <div v-else>
        <p>
          {{ $t('termo_1') }}
        </p>
        <p>
          {{ $t('termo_2') }}
          <strong>{{ $t('taxa_cancelamento') }}</strong> {{ $t('termo_3') }}
        </p>

        <p>
          {{ $t('termo_4') }}
          <span style="font-weight: bold; color: #2c3e50;">{{ nomeLaboratorio }}</span>
          {{ $t('termo_5') }}
          <span style="font-weight: bold; color: #2c3e50;">{{ nomeLaboratorio }}</span>
          {{ $t('termo_6') }}
          <span style="font-weight: bold; color: #2c3e50;">{{ nomeLaboratorio }}</span> {{ $t('termo_7') }}
        </p>

        <p>
          {{ $t('termo_8') }}
        </p>


      </div>
    </div>

    <b-alert v-if="permitirConcordar" variant="success" show>
      {{ $t('Você_leu_todos_os_termos_e_Concordou') }}
    </b-alert>

    <div class="d-flex justify-content-end mt-3">
      <b-button
          variant="success"
          @click="concordar"
          click="$bvModal.hide('modalTermos')"
          :disabled="aceitou">
          {{ aceitou ? $t('Você_ja_concordou') : $t('Concordo') }}
      </b-button>
      <b-button
          variant="primary"
          @click="$bvModal.hide('modalTermos')" class="ml-2"
      >
        {{ $t('Fechar') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import {BModal, BButton, BAlert, BFormCheckbox} from 'bootstrap-vue'

export default {
  name: 'ModalTermosServico',
  components: {
    BFormCheckbox,
    BModal,
    BButton,
    BAlert
  },
  props: ['nomeLaboratorio', 'permitirConcordar'],
  data() {
    return {
      localPermitirConcordar: this.permitirConcordar
    };
  },

  computed: {
    ...mapState({
      cliente: state => state.tabelas.clienteConfig
    }),

    aceitou(){
      return this.permitirConcordar
    }
  },

  watch: {
    permitirConcordar(newVal) {
      this.localPermitirConcordar = newVal;
    }
  },


  methods: {
    concordar() {
      this.localPermitirConcordar = true;
      this.$emit('termosAceitos', true);
      this.$bvModal.hide('modalTermos')
    }
  }
}
</script>